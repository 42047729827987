import { Box, Button, TextField, Typography } from '@mui/material'
import React from 'react'
import { Images } from '../../../asset/images'
import {
    BgImage,
    NewTechnologyButton,
    NewTechnologyEmail,
    NewTechnologyForm,
    NewTechnologyTextField,
    NewTechnologyTitle,
} from './newtechnology.style'

const GetNewTechnologyEmail = () => {
    return (
        <Box
            sx={{
                padding: {
                    lg: '6.3125rem 3rem',
                    md: '6.3125rem 2rem',
                    sm: '6.3125rem 2rem',
                    xs: '3rem 1.25rem',
                },
            }}
        >
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    gap: '2rem',
                    maxWidth: '1296px',
                    margin: 'auto',
                    padding: {
                        lg: '8rem 0',
                        md: '6rem 30px',
                        sm: '6rem 30px',
                        xs: '6rem 30px',
                    },
                    background: `url(${Images.GetNewTechnlogy})`,
                    backgroundSize: '100% 100%',
                    backgroundRepeat: 'no-repeat',
                    backgroundPosition: 'center',
                }}
            >
                <Box>
                    <img loading="eager" src={Images.Messages} alt="Messages" />
                </Box>
                <NewTechnologyTitle>
                    Get Notified of latest news and technology
                </NewTechnologyTitle>
                <NewTechnologyEmail>
                    Enter your mail to get started
                </NewTechnologyEmail>
                <NewTechnologyForm component="form">
                    <NewTechnologyTextField
                        variant="outlined"
                        placeholder="Enter your e-mail address"
                        fullWidth
                    />
                    <NewTechnologyButton type="submit" variant="contained">
                        <span
                            style={{
                                background:
                                    'linear-gradient(180deg, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 1))',
                                backgroundClip: 'text',
                                WebkitBackgroundClip: 'text',
                                WebkitTextFillColor: 'transparent',
                                color: 'transparent',
                            }}
                        >
                            Submit
                        </span>
                    </NewTechnologyButton>
                </NewTechnologyForm>
            </Box>
        </Box>
    )
}

export default GetNewTechnologyEmail
