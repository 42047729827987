import React from 'react'
import {
    CircleBlur,
    GreenBlur,
    Team,
    TeamBox,
    TeamCardBox,
    TeamDescription,
    TeamHead,
    TeamOut,
} from '../../teamgroupStyle'
import { cuddaloreRangers } from '../../TeamInfo'
import TeamCard from '../../teamCards'
import des from '../../../../../asset/images/cubes.webp'
import blur from '../../../../../asset/images/green blur.webp'
import { Images } from '../../../../../asset/images'

function CudAnalist() {
    return (
        <>
            <TeamOut>
                <CircleBlur>
                    <img
                        loading="eager"
                        src={Images.Cubes}
                        alt="cubes"
                        style={{ width: '100%' }}
                    />
                </CircleBlur>
                <GreenBlur>
                    <img
                        loading="eager"
                        src={Images.Greenblur}
                        alt="House of Websites"
                        style={{ width: '100%' }}
                    />
                </GreenBlur>

                <Team>
                    <TeamBox>
                        <TeamHead>Requirement Rangers</TeamHead>
                        <TeamDescription>
                            Our business analysts expertly bridge client needs
                            and technical solutions, ensuring every project is
                            perfectly aligned with your goals. 
                        </TeamDescription>
                    </TeamBox>
                    <TeamCardBox>
                        {cuddaloreRangers.map((data, index) => (
                            <TeamCard key={index} data={data} />
                        ))}
                    </TeamCardBox>
                </Team>
            </TeamOut>
        </>
    )
}

export default CudAnalist
