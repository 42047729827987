import React from 'react'

import {
    CubeBlur,
    PurpleBlur,
    StarBlur,
    Team,
    TeamBox,
    TeamCardBox,
    TeamDescription,
    TeamHead,
    TeamOut,
    Star,
} from '../../teamgroupStyle'
import {
    backend,
    cuddaloreCoders,
    cuddaloreCodingCrew,
    developerObject,
} from '../../TeamInfo'
import TeamCard from '../../teamCards'
import blur from '../../../../../asset/images/cubeblur.webp'
import star from '../../../../../asset/images/Star 17.webp'
import purp from '../../../../../asset/images/purpleblur.webp'
import { Images } from '../../../../../asset/images'

function CudDeveloper() {
    return (
        <>
            <TeamOut>
                <CubeBlur>
                    <img
                        loading="eager"
                        src={Images.Cubeblur}
                        style={{ width: '100%' }}
                        alt="Cubeblur"
                    />
                </CubeBlur>
                <PurpleBlur>
                    <img
                        loading="eager"
                        src={Images.Purpleblur}
                        alt="House of Websites"
                        style={{ width: '100%' }}
                    />
                </PurpleBlur>

                <Team>
                    <TeamBox>
                        <TeamHead>Coding Crew</TeamHead>
                        <TeamDescription>
                            Our coding experts bring digital dreams to life,
                            delivering flawless, high-performance websites with
                            precision and innovation. 
                        </TeamDescription>
                    </TeamBox>
                    <TeamCardBox>
                        {cuddaloreCoders.map((data, index) => (
                            <TeamCard key={index} data={data} />
                        ))}
                    </TeamCardBox>
                </Team>
            </TeamOut>

            <TeamOut>
                <StarBlur>
                    <img
                        loading="eager"
                        src={Images.Purpleblur}
                        alt="House of Websites"
                        style={{ width: '100%' }}
                    />
                </StarBlur>
                {/* <Star>
          <img loading="eager" src={star} style={{width:'100%'}}/>
        </Star> */}
                <Team>
                    <TeamCardBox>
                        {cuddaloreCodingCrew.map((data, index) => (
                            <TeamCard key={index} data={data} />
                        ))}
                    </TeamCardBox>
                </Team>
            </TeamOut>
        </>
    )
}

export default CudDeveloper
