import React, { useState } from 'react'
import { Box, Typography } from '@mui/material'
import { Images } from '../../asset/images'
import { StyledVideo, thumbnailImage } from './landing.style'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
const LandingVideo = () => {
    const [isVideoEnabled, setIsVideoEnabled] = useState(false)
    const handleTextClick = () => {
        setIsVideoEnabled(true)
    }

    return (
        <div>
            <Box
                sx={{
                    background: 'rgba(15, 1, 33, 1)',
                    // maxWidth:'1296px',
                    // margin:'0 auto'
                }}
            >
                <Box
                    sx={{
                        margin: 'auto',
                        maxWidth: '1296px',
                        padding: {
                            lg: '0 11rem',
                            md: '0 11rem',
                            sm: '0 5rem',
                            xs: '0 2rem',
                        },
                    }}
                >
                    {isVideoEnabled ? (
                        <StyledVideo
                            src={Images?.HOW_VIDEO}
                            autoPlay
                            muted
                            loop
                        >
                            Your browser does not support the video tag.
                        </StyledVideo>
                    ) : (
                        <Box
                            sx={{
                                ...thumbnailImage,
                                position: 'relative', // Needed for positioning the "play" text
                            }}
                        >
                            <img
                                loading="eager"
                                src={Images.WorkCompImg}
                                alt="TeamGroup"
                                style={{
                                    width: '100%',
                                }}
                            />
                            <div
                                style={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    color: 'white', // Adjust color for better visibility
                                    // fontSize: '3rem', // Adjust font size as needed
                                    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Optional: add a semi-transparent background
                                    padding: '1.5rem 1.5rem', // Optional: add padding
                                    borderRadius: '50px', // Optional: rounded corners
                                    cursor: 'pointer', // Add pointer cursor for clickability
                                }}
                                onClick={() => handleTextClick()}
                            >
                                <PlayArrowIcon />
                                {/* Play */}
                            </div>
                        </Box>
                    )}
                </Box>
            </Box>
        </div>
    )
}

export default LandingVideo
