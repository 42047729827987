import React from 'react'
import { TextGrayColor, TextGrayTitle } from './corevalues.style'
import { Box, Typography } from '@mui/material'
import { AppContainer } from '../career.style'
import './corevalue.css'
import { Stack } from '@mui/material'
import { multi_Diversity } from '../../../constants'

function Diversity(props) {
    return (
        <Box
            sx={{
                flexBasis: { md: '31%', sm: '45%', xs: '100%' },
                marginLeft: '0 !important',
                marginBottom: { xl: '60px ', md: '40px ', sm: '40px ' },
            }}
            data-aos="zoom-in"
            data-aos-delay={props.index * 100}
            data-aos-duration={props.fade_time}
        >
            <Box sx={{ textAlign: { sm: 'left', xs: 'left' } }}>
                <Box>
                    <img
                        loading="eager"
                        src={props.div_img}
                        alt="careers at House of Websites"
                    />
                </Box>
                <Typography
                    sx={{
                        fontSize: { xl: '24px', lg: '24px', md: '20px' },
                        fontFamily: 'Dystopian',
                        fontWeight: '700',
                        my: '16px',
                    }}
                >
                    {props.div_headline}
                </Typography>
                <Typography
                    sx={{
                        fontSize: { xl: '20px', lg: '20px', md: '18px' },
                        fontFamily: 'Lufga',
                        fontWeight: '400',
                        color: '#333333',
                        lineHeight: { xl: '32px', lg: '32px', md: '28px' },
                    }}
                >
                    {props.div_para}
                </Typography>
            </Box>
        </Box>
    )
}

function Corevalues() {
    document.addEventListener('DOMContentLoaded', function () {
        const textWrapper = document.querySelector('.text-example')
        textWrapper.innerHTML = textWrapper.textContent.replace(
            /\S/g,
            (match, index) => `<span style="--i: ${index + 1}">${match}</span>`
        )
    })
    return (
        <Box
            sx={{
                backgroundColor: '#f7f7f7',
                paddingTop: '40px',
                paddingBottom: '50px',
            }}
        >
            <AppContainer>
                <Box>
                    <TextGrayTitle className="text-example">
                        <span className="letter" style={{ '--i': 1 }}>
                            O
                        </span>
                        <span className="letter" style={{ '--i': 2 }}>
                            u
                        </span>
                        <span className="letter" style={{ '--i': 3 }}>
                            r
                        </span>
                        <span className="letter" style={{ '--i': 4 }}>
                            C
                        </span>
                        <span className="letter" style={{ '--i': 5 }}>
                            o
                        </span>
                        <span className="letter" style={{ '--i': 6 }}>
                            r
                        </span>
                        <span className="letter" style={{ '--i': 7 }}>
                            e
                        </span>
                    </TextGrayTitle>
                    <br />
                    <TextGrayColor className="text-examplesub">
                        <span
                            className="letter text-examplesub"
                            style={{ '--i': 1 }}
                        >
                            V
                        </span>
                        <span
                            className="letter text-examplesub"
                            style={{ '--i': 2 }}
                        >
                            a
                        </span>
                        <span
                            className="letter text-examplesub"
                            style={{ '--i': 3 }}
                        >
                            l
                        </span>
                        <span
                            className="letter text-examplesub"
                            style={{ '--i': 4 }}
                        >
                            u
                        </span>
                        <span
                            className="letter text-examplesub"
                            style={{ '--i': 5 }}
                        >
                            e
                        </span>
                        <span
                            className="letter text-examplesub"
                            style={{ '--i': 6 }}
                        >
                            s
                        </span>
                    </TextGrayColor>
                </Box>

                <Stack
                    direction="row"
                    spacing={3}
                    sx={{
                        flexWrap: 'wrap',
                        rowGap: {
                            lg: '64px',
                            md: '45px',
                            sm: '40px',
                            xs: '30px',
                        },
                        justifyContent: 'space-between',
                        // columnGap: {lg: "42px", md: "30px", sm: "32px", xs: "20px"}
                    }}
                >
                    {multi_Diversity.map((multi_Diver_single, index) => (
                        <Diversity
                            key={index}
                            index={index}
                            div_img={multi_Diver_single.div_img}
                            div_headline={multi_Diver_single.div_headline}
                            div_para={multi_Diver_single.div_para}
                        />
                    ))}
                </Stack>
            </AppContainer>
        </Box>
    )
}

export default Corevalues
