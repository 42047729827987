// import React from 'react'
// import Typography from '@mui/material/Typography';
// import Container from '@mui/material/Container';
// import Box from '@mui/material/Box';
// import Stack from '@mui/material/Stack';
// import { styled } from '@mui/material/styles';
// import PropTypes from "prop-types";
// import Grid from '@mui/material/Grid';
// import zIndex from '@mui/material/styles/zIndex';

// import { UserData } from '../../constants';
// import FloatingLabelInput from '../.././pages/contactus/FloatingLabelInput';

// import { CircleBlueBg, CircleGreenBg, ContactOne, FontSize12, FontSize14400, FontSize16400,FontSize16500, FontSize16400before, FontSize18600, FontSize18left, FontSize48700, GensisSecTitle } from './contactus_style';
// import { Images } from '../../asset/images'

// // hi from react

// const EmailLink = () => (
//   <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
//     <img loading="eager" src={Images.MailThat} alt="Mail Icon" style={{ verticalAlign: 'middle' }} />
//     <a href="mailto:rebecca.j@hepl.com" className="mailto" style={{ textDecoration: 'none', color: 'white',fontSize:"16px",fontWeight:"500",fontFamily:"lufga" }}>
//       rebecca.j@hepl.com
//     </a>
//   </div>
// );

// const PhoneConnect = () =>(
//   <div style={{ display: 'flex', alignItems: 'center', gap: '10px',margin:'20px 0' }}>
//   <img loading="eager" src={Images.PhoneCall} alt="Mail Icon" style={{ verticalAlign: 'middle' }} />
//   <a href="mailto:rebecca.j@hepl.com" className="mailto" style={{ textDecoration: 'none', color: 'white',fontSize:"16px",fontWeight:"500",fontFamily:"lufga" }}>
//   Book a call
//   </a>
//   </div>
// );

// function Diversity(props){
//   return (
//     <ContactOne>
//       <Box sx={{position:"relative",m:"0 0 10px 0"}}>
//         <img loading="eager" src={props.img} alt="phot" className="img-round" />
//         <Box component="img" src={props.in} alt="Lin" sx={{ position:"absolute",  bottom: "-7px",
//            left: "50%",transform:"translate(-50%)"
//         }}></Box>

//       </Box>
//       <FontSize16500 sx={{color:"#fff" ,m:"0"}}>{props.name}</FontSize16500>
//       <FontSize14400 >{props.roll}</FontSize14400>
//     </ContactOne>
//   );
// };

// // indexer.propTypes = {
// //   img: PropTypes.string.isRequired,
// //   in: PropTypes.string.isRequired,
// //   name: PropTypes.string.isRequired,
// //   roll: PropTypes.string.isRequired,
// // };
// const index = () => {
//   return (
//     <Box sx={{
//       backgroundColor:"#070707",
//       }} >
//     <Box sx={{
//      position:"relative",
//     }}>
//       <CircleGreenBg >
//           <img loading="eager" src={Images.contactbg2} alt="img"  style={{width:"100%"}}/>

//           </CircleGreenBg>

//                     <Container maxWidth="xl"
//                     sx={{
//                     backgroundImage:`url(${Images.contactbannerbg})`,
//                     backgroundRepeat:"no-repeat",
//                     backgroundSize:"cover",
//                     position:"relative",

//                 }}
//                     >

//                         <Box sx={{
//                         }}>

//             <Stack direction="row"  gap={4} sx={{flexWrap:{lg:"nowrap", md:"wrap",sm:"wrap",xs:"wrap"},justifyContent:{lg:"spaceBetween",xs:"center"},paddingTop:"150px",
//             flexDirection: { xs: 'column', md: 'row' },

//             }}>
//             <Box  sx={{border: "2px solid #3EC5FF", borderRadius: "32px",px: "40px", flex: { lg: 4, md: 12, sm: 12, xs: 12 }, p:{md:"48px", xs:"20px"}}}>
//             <Grid container spacing={2}>
//                 {UserData.map((user, index) => (
//                     <Grid item xs={4}
//                               sx={{
//                               display: 'flex',
//                               flexDirection: 'column',
//                               justifyContent: 'center',
//                               alignItems: 'center',
//                               }}
//                     key={index}> {/* Add item prop here */}
//                     <Diversity
//                         img={user.img}
//                         in={user.in}
//                         name={user.name}
//                         roll={user.roll}
//                     />
//                     </Grid>
//                 ))}
//                 </Grid>

//                         <Box sx={{marginBottom:"45px"}}>
//                             <Stack direction="column"
//                             sx={{display:"flex",alignItems:"flex-start"}}
//                             >

//                                 <FontSize16400before  sx={{color:"#fff"}}> We will respond within 12 hours</FontSize16400before>
//                                 <FontSize16400before  sx={{color:"#fff"}}>We'll sign an NDA if requested</FontSize16400before>
//                                 <FontSize16400before  sx={{color:"#fff"}}>Access to dedicated product specialists</FontSize16400before>

//                             {/* ============Tick mrk finished================= */}

//                             </Stack>
//                         </Box>
//                         {/* left -second finished */}
//                         <Box>
//                             <FontSize14400 sx={{color:"#fff",textAlign:"left"}}>Project inquires</FontSize14400>
//                             <EmailLink />
//                             <PhoneConnect />

//                         </Box>
//                         {/* ======================================================= */}

//                         </Box>

//                         {/* ====================3 photo section is finished================== */}

//                         {/* ================left Box finished================================== */}
//                         <Box sx={{

//                         flex: { lg: 8, md: 12, sm: 12, xs: 12 },

//                     }}>
//                     <Box sx={{
//                         backgroundColor:"#fff",
//                         p:{xl:"56px 52px 150px 52px",lg:"56px 52px 150px 52px",md:"56px 52px", xs:"20px"},
//                         textAlign:"left",
//                         color:"#000",
//                         borderRadius:"30px",
//                         flex:{lg:8,md:12},
//                         zIndex:"99999"
//                         }}>
//                                 <FontSize48700>Tell us about your project</FontSize48700>

//                                 <form action="">
//                                     <Grid container spacing={2} sx={{ margin: "0 0 50px 0" }}>
//                                         <Grid item xl={6} lg={6} md={12} xs={12} sx={{ width: "100%", paddingLeft: "0px !important" }}>
//                                         <FloatingLabelInput label="First Name:" name="first-name" />
//                                         </Grid>
//                                         <Grid item  xl={6} lg={6} md={12} xs={12} sx={{ width: "100%", paddingLeft: "0px !important" }}>
//                                         <FloatingLabelInput label="Email Address:" name="email" type="email" />
//                                         </Grid>
//                                     </Grid>
//                                     <Grid container spacing={2} sx={{ margin: "0 0 50px 0" }}>
//                                         <Grid item xs={12} sx={{ width: "100%", paddingLeft: "0px !important" }}>
//                                         <FloatingLabelInput label="About Project:" name="about-project" />
//                                     </Grid>
//                                     </Grid>
//                                     <Grid container >
//                                         <Grid item  xl={6} lg={6} md={12} xs={12} sx={{mt:{xl:"30px",lg:"30px"},mb:{md:"30px",lg:"30px",xl:"30px"}}}>

//                                         <FontSize12>By submitting this form you agree to our </FontSize12>
//                                         <Typography><strong>Terms of Use </strong> and{" "}
//                                             <strong>Privacy policy</strong>
//                                             </Typography>
//                                         </Grid>
//                                         {/* ======================================== */}
//                                         <Grid item  xl={6} lg={6} md={12} xs={12} sx={{mt:{xl:"30px",lg:"30px"},}}>
//                                         <Box sx={{display:"flex",alignItems:"center",justifyContent:"right"}}>
//                                             <Box sx={{  padding:"12px",borderRadius:"16px",backgroundColor:"#07E8EE"}}>
//                                                 <img loading="eager" src={Images.DownArrow} alt="img" />

//                                             </Box>
//                                             <Typography sx={{   padding:"20px 52px",borderRadius:"16px",backgroundColor:"#07E8EE",fontSize:"18px",
//                                             cursor:"pointer"}}>Submit</Typography>
//                                         </Box>
//                                         </Grid>
//                                           {/* ======================================== */}

//                                     </Grid>

//                                 </form>
//                         </Box>
//                         </Box>
//                     </Stack>
//                     </Box>

//                 </Container>
//    {/* 1st section finished */}

//                 <Container maxWidth="xl" >
//                 <Grid container spacing={4} sx={{ mt: "70px", mb: "100px", justifyContent: "center" }}>
//             <Grid item xs={12} md={4} lg={2} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
//               <img loading="eager" src={Images.Automatic} alt="" />
//             </Grid>
//             <Grid item xs={12} md={4} lg={2} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
//               <img loading="eager" src={Images.Wordpress} alt="" />
//             </Grid>
//             <Grid item xs={12} md={4} lg={2} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
//               <img loading="eager" src={Images.Interpre} alt="" />
//             </Grid>
//             <Grid item xs={12} md={4} lg={2} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
//               <img loading="eager" src={Images.InterPlayer} alt="" />
//             </Grid>
//             <Grid item xs={12} md={4} lg={2} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
//               <img loading="eager" src={Images.BlockWorks} alt="" />
//             </Grid>
//             <Grid item xs={12} md={4} lg={2} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
//               <img loading="eager" src={Images.NewNew} alt="" />
//             </Grid>
//           </Grid>
//                 </Container>

//                 <Container maxWidth="xl">
//           <Box sx={{pb:"100px",position:'relative'}}>
//           <CircleBlueBg >
//           <img loading="eager" src={Images.footerbg} alt="img"  style={{width:"100%"}}/>
//           </CircleBlueBg>
//               <GensisSecTitle sx={{color:"#fff"}}>What are the next steps?</GensisSecTitle>
//               <Stack direction="row" spacing={4} gap={3} sx={{flexWrap:{md:"nowrap",xs:"wrap"},justifyContent:"center"}}>
//               <Box sx={{ flexBasis:{md:"25%",sm:"46%",xs:"80%" },margin:{sm:"0 auto !important"}}}>
//                   <Grid container spacing={2} >
//                     <Grid item > <img loading="eager" src={Images.Video} alt="" /> </Grid>
//                    <Grid item > <FontSize18600 sx={{color:"#fff"}}>STEP 1</FontSize18600></Grid>

//                   </Grid>
//                   <FontSize18left sx={{color:"#fff"}}>
//                     Our representative contacts you within 24 hours.
//                   </FontSize18left>
//                 </Box>
//                 {/* ==============================1================================== */}
//                 <Box sx={{ flexBasis:{md:"24%",sm:"46%",xs:"80%" },margin:"0 auto !important"}}>
//                   <Grid container spacing={2} >
//                     <Grid item > <img loading="eager" src={Images.AvatarUser} alt="" /> </Grid>
//                    <Grid item > <FontSize18600 sx={{color:"#fff"}}>STEP 2</FontSize18600></Grid>

//                   </Grid>
//                   <FontSize18left sx={{color:"#fff"}}>
//                   {/* We clarify project details and necessary requirements from
//                   you. */}
//                    Our representative contacts you within 24 hours.
//                   </FontSize18left>
//                 </Box>
//                 {/* ================================2================================ */}
//                 <Box sx={{ flexBasis:{md:"25%",sm:"46%",xs:"80%" },margin:"0 auto !important"}}>
//                   <Grid container spacing={2} sx={{}}>
//                     <Grid item > <img loading="eager" src={Images.SearchWhat} alt="" /> </Grid>
//                    <Grid item > <FontSize18600 sx={{color:"#fff"}}>STEP 3</FontSize18600></Grid>

//                   </Grid>
//                   <FontSize18left sx={{color:"#fff"}}>
//                   We analyze and review collected requiremements and form up the brief.
//                   </FontSize18left>
//                 </Box>
//                 {/* =================================3=============================== */}
//                 <Box  sx={{ flexBasis:{md:"25%",sm:"46%",xs:"80%" },margin:"0 auto !important"}}>
//                   <Grid container spacing={2} sx={{}}>
//                     <Grid item > <img loading="eager" src={Images.StepMail} alt="" /> </Grid>
//                    <Grid item > <FontSize18600 sx={{color:"#fff"}}>STEP 4</FontSize18600></Grid>

//                   </Grid>
//                   <FontSize18left sx={{color:"#fff"}}>
//                   We form up the estimates and present you the proposal.
//                   </FontSize18left>
//                 </Box>
//                 {/* =================================4=============================== */}

//               </Stack>

//       </Box>
//       </Container>
//             </Box>

//         </Box>

// );
// };
// export default index;

import React from 'react'
import ContactMain from './contactmain'
import ContactStep from './contactsteps'
import { Helmet } from 'react-helmet-async'

function ContactUs() {
    return (
        <>
            <Helmet>
                <title>
                    Contact Us for SEO & Web Development Services at HOW
                </title>
                <meta
                    name="title"
                    content="Contact Us for SEO & Web Development Services at HOW"
                />
                <meta
                    name="description"
                    content="Contact HOW for expert SEO and web development services. Let's enhance your digital presence—reach out today to begin your project!"
                />
                <meta
                    name="keywords"
                    content="contact House of Websites,
                              Expert web design,
                              leading web design company Chennai,
                              contact us Chennai,
                              business inquiries"
                />
                <link
                    rel="canonical"
                    href="https://houseofwebsites.com/contactus"
                />
                <meta
                    property="og:title"
                    content="Contact Us for SEO & Web Development Services at HOW"
                />
                <meta
                    property="og:description"
                    content="Contact HOW for expert SEO and web development services. Let's enhance your digital presence—reach out today to begin your project!"
                />
                <meta
                    property="og:url"
                    content="https://houseofwebsites.com/contactus"
                />
                <meta
                    property="og:image"
                    content="https://houseofwebsites.com/static/media/HowLogo.3d6f3ec8dd4c57827d4f80e8e623f64c.svg"
                />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="House of Websites" />
                <meta property="og:locale" content="en_IN" />
                <meta
                    name="twitter:title"
                    content="Contact Us for SEO & Web Development Services at HOW"
                />
                <meta
                    name="twitter:description"
                    content="Contact HOW for expert SEO and web development services. Let's enhance your digital presence—reach out today to begin your project!"
                />
                <meta
                    name="twitter:url"
                    content="https://houseofwebsites.com/contactus"
                />
                <meta
                    name="twitter:image"
                    content="https://houseofwebsites.com/static/media/HowLogo.3d6f3ec8dd4c57827d4f80e8e623f64c.svg"
                />
            </Helmet>
            <ContactMain />
            <ContactStep />
        </>
    )
}

export default ContactUs
