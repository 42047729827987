import { Box, Typography } from '@mui/material'
import React from 'react'
import { Images } from '../../../asset/images'
import { ServiceBannerCon, ServiceBannerOuter } from './serviceBannerStyle'

function ServiceBanner() {
    return (
        <>
            <Box sx={ServiceBannerOuter}>
                <Box sx={ServiceBannerCon}>
                    <Box
                        sx={{
                            maxWidth: '771px',
                            color: '#F7F7F7',
                            margin: '190px 0',

                            '@media (max-width:992px)': {
                                margin: '150px 0',
                            },
                        }}
                    >
                        <Typography
                            variant="h1"
                            sx={ServiceBannerCon.firstContent}
                        >
                            Your vision,
                            <br />
                            our Passion
                            <br /> web design that 'wow' ur company
                        </Typography>
                        <Typography
                            variant="p"
                            sx={ServiceBannerCon.secontContent}
                        >
                            At House of Websites, we bring your ideas to life
                            through captivating web design, innovative
                            development, and seamless user experiences. From
                            UI/UX design to responsive builds, every detail is
                            crafted with precision to create unforgettable
                            online experiences.
                        </Typography>
                    </Box>
                </Box>
            </Box>
        </>
    )
}

export default ServiceBanner
