import React from 'react'
import {
    CubeBlur,
    PurpleBlur,
    Star,
    StarBlur,
    Team,
    TeamBox,
    TeamCardBox,
    TeamDescription,
    TeamHead,
    TeamOut,
} from '../../teamgroupStyle'
import { backend, developerObject } from '../../TeamInfo'
import TeamCard from '../../teamCards'
import { Box } from '@mui/material'
import blur from '../../../../../asset/images/cubeblur.webp'
import star from '../../../../../asset/images/Star 17.webp'
import purp from '../../../../../asset/images/purpleblur.webp'
import { Images } from '../../../../../asset/images'

function Developer() {
    return (
        <>
            <TeamOut>
                <CubeBlur>
                    <img
                        loading="eager"
                        src={Images.Cubeblur}
                        style={{ width: '100%' }}
                        alt="Cubeblur"
                    />
                </CubeBlur>
                <PurpleBlur>
                    <img
                        loading="eager"
                        src={Images.Purpleblur}
                        style={{ width: '100%' }}
                        alt="Purpleblur"
                    />
                </PurpleBlur>
                <Team>
                    <TeamBox>
                        <TeamHead>Coding Crew</TeamHead>
                        <TeamDescription>
                            Our coding experts bring digital dreams to life,
                            delivering flawless, high-performance websites with
                            precision and innovation. 
                        </TeamDescription>
                    </TeamBox>
                    <TeamCardBox>
                        {developerObject.map((data) => (
                            <TeamCard data={data} />
                        ))}
                    </TeamCardBox>
                </Team>
            </TeamOut>

            <TeamOut>
                <StarBlur>
                    <img
                        loading="eager"
                        src={Images.Purpleblur}
                        style={{ width: '100%' }}
                        alt="Purpleblur"
                    />
                </StarBlur>
                {/* <Star>
          <img loading="eager" src={star} style={{width:'100%'}} alt="star"/>
        </Star> */}
                <Team>
                    <TeamCardBox>
                        {backend.map((data) => (
                            <TeamCard data={data} />
                        ))}
                    </TeamCardBox>
                </Team>
            </TeamOut>
        </>
    )
}

export default Developer
