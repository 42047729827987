import React from 'react'

import {
    AppContainer,
    BannerSection,
    HorizontalLine,
    JoinBtn,
    StyledGradient,
} from './career.style'
import { Box, Typography } from '@mui/material'
import Corevalues from './corevalues'
import Curentopenings from './openings'
import CareerSlider from './careerslider'
import Glassdoor from './glassdoor'
import { Images } from '../../asset/images'
import AOS from 'aos'
import 'aos/dist/aos.css' // You can also use <link> for styles
import { Helmet } from 'react-helmet-async'
AOS.init()
export default function Careers() {
    return (
        <>
            <Helmet>
                <title>
                    Careers at House of Websites | Join Our Creative Team
                </title>
                <meta
                    name="title"
                    content="Careers at House of Websites | Join Our Creative Team"
                />
                <meta
                    name="description"
                    content="Join our House Of Websites and explore more career opportunities like web development and digital marketing in a dynamic and innovative environment."
                />
                <meta
                    name="keywords"
                    content="Career Opportunities,
                            job opportunities Chennai,
                            web design jobs,
                            join our team"
                />
                <link
                    rel="canonical"
                    href="https://houseofwebsites.com/careers"
                />
                <meta
                    property="og:title"
                    content="Careers at House of Websites | Join Our Creative Team"
                />
                <meta
                    property="og:description"
                    content="Join our House of Websites and explore more career opportunities like web development and digital marketing in a dynamic and innovative environment."
                />
                <meta
                    property="og:url"
                    content="https://houseofwebsites.com/careers"
                />
                <meta
                    property="og:image"
                    content="https://houseofwebsites.com/static/media/HowLogo.3d6f3ec8dd4c57827d4f80e8e623f64c.svg"
                />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="House of Websites" />
                <meta property="og:locale" content="en_IN" />
                <meta
                    name="twitter:title"
                    content="Careers at House of Websites | Join Our Creative Team"
                />
                <meta
                    name="twitter:description"
                    content="Join our House of Websites and explore more career opportunities like web development and digital marketing in a dynamic and innovative environment."
                />
                <meta
                    name="twitter:url"
                    content="https://houseofwebsites.com/careers"
                />
                <meta
                    name="twitter:image"
                    content="https://houseofwebsites.com/static/media/HowLogo.3d6f3ec8dd4c57827d4f80e8e623f64c.svg"
                ></meta>
            </Helmet>
            <Box>
                <BannerSection>
                    <video
                        src={Images?.CareerVideo}
                        autoPlay
                        muted
                        loop
                        style={{
                            width: '100%',
                            height: '100%',
                            position: 'absolute',
                            objectFit: 'cover',
                            top: '0',
                        }}
                    >
                        Your browser does not support the video tag.
                    </video>
                    <AppContainer>
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                gap: '24px',
                            }}
                        >
                            <Typography
                                variant="h1"
                                sx={{
                                    fontSize: {
                                        xl: 80,
                                        lg: 80,
                                        md: 72,
                                        sm: 50,
                                        xs: 27,
                                    },
                                    fontFamily: 'Dystopian',
                                    fontWeight: '700',
                                    color: '#fff',
                                    letterSpacing: '1.2px',
                                    paddingTop: {
                                        xl: '210px',
                                        lg: '210px',
                                        md: '210px',
                                        sm: '210px',
                                        xs: '150px',
                                    },
                                }}
                                data-aos="fade-up"
                                data-aos-duration="1000"
                            >
                                <StyledGradient>Career</StyledGradient> With{' '}
                                <br></br>
                                {/* <HorizontalLine></HorizontalLine> */}
                                House of Websites
                            </Typography>

                            <Typography
                                sx={{
                                    fontSize: {
                                        xl: 20,
                                        lg: 20,
                                        md: 18,
                                        sm: 18,
                                        xs: 16,
                                    },
                                    fontFamily: 'Lufga',
                                    fontWeight: '300',
                                    color: '#fff',
                                    lineHeight: {
                                        lg: '32px',
                                        lg: '32px',
                                        md: '30px',
                                        sm: '30px',
                                        xs: '28px',
                                    },
                                    width: {
                                        xl: '58%',
                                        lg: '58%',
                                        md: '75%',
                                        sm: '95%',
                                        xs: '100%',
                                    },
                                }}
                                data-aos="fade-up"
                                data-aos-duration="1400"
                            >
                                Looking for a career that's all about making,
                                shaping, and celebrating the extraordinary? then
                                you are home!
                            </Typography>

                            <a href="#career-navigate" aria-label="dashboard">
                                <JoinBtn>Join our Team</JoinBtn>
                            </a>
                        </Box>
                    </AppContainer>
                </BannerSection>
                <Box
                    sx={{
                        height: { xl: 300, lg: 300, md: 175, sm: 130, xs: 135 },
                        width: '100%',
                        position: 'relative',
                    }}
                >
                    <AppContainer>
                        <CareerSlider />
                    </AppContainer>
                </Box>
                <Corevalues />
                <Glassdoor />
                <Curentopenings />
            </Box>
        </>
    )
}
