import React from 'react'
import {
    OurStoryBanner,
    OurStoryDescription,
    OurStoryDescriptionBanner,
    OurStoryTitle,
} from '../about-banner/aboutus-style'
import BarLine from '../about-banner/BarLine'
import { Box, Typography } from '@mui/material'
import { Images } from '../../../asset/images'

const AboutusOurStory = () => {
    return (
        <OurStoryBanner>
            <OurStoryTitle>Our Story</OurStoryTitle>

            <BarLine />

            <OurStoryDescriptionBanner>
                <OurStoryDescription>
                    Our origin story began in the fascinating town of Cuddalore,
                    where a small team of passionate innovators huddled in a
                    modest office, where we didn't just build websites – we
                    crafted them with user-centric craftsmanship, ensuring every
                    click flowed with intuitive brilliance.
                </OurStoryDescription>
                <OurStoryDescription>
                    Starting from our humble beginnings in Cuddalore, where
                    resourcefulness met determination, we’ve evolved into the
                    company we are today, obsessed with nailing every project
                    and injecting it with a dose of super creativity.
                </OurStoryDescription>
            </OurStoryDescriptionBanner>

            <Box
                component="img"
                src={Images.OurStoryTemple}
                alt="Chennai web agency portfolio"
                sx={{ width: '100%', marginBottom: '-4px' }}
            />
        </OurStoryBanner>
    )
}

export default AboutusOurStory
