import React from 'react'
import { routes } from '../../routes'

const Notfound=() =>{
  return (
    <div style={{background:'#0F0121',height:"70vh"}}>
        <h1>We can't seem to find the page you are looking for. </h1>
        <p>Visit <a href={routes?.DASHBOARD} aria-label="dashboard">houseofwebsites.com/</a> </p> 
      
    </div>
  )
}

export default Notfound
