import React from 'react'
import ServiceBanner from './serviceBanner'
import ServiceTab from './servicesTab'
import ServiceStep from './serviceSteps'
import ServiceAccordian from './serviceAccordian'
import { Helmet } from 'react-helmet-async'

function Servcies() {
    return (
        <>
            <Helmet>
                <title>
                    Creative Web Design, Branding & SEO Services | HOW
                </title>
                <meta
                    name="title"
                    content="Creative Web Design, Branding & SEO Services | HOW"
                />

                <meta
                    name="description"
                    content="HOW is a creative web design company offering  Web Design, branding & SEO services. Let us help you elevate your online presence—contact us today!"
                />
                <meta
                    name="keywords"
                    content="Creative Web Development, eCommerce web design, eCommerce website development, custom online store design, eCommerce website company, eCommerce solutions, eCommerce website design company, online store development"
                />
                <link
                    rel="canonical"
                    href="https://houseofwebsites.com/services"
                />
                <meta
                    property="og:title"
                    content="Web Design, Branding & SEO Services | HOW"
                />
                <meta
                    property="og:description"
                    content="HOW is a creative web design company offering  Web Design, branding & SEO services. Let us help you elevate your online presence—contact us today!"
                />
                <meta
                    property="og:url"
                    content="https://houseofwebsites.com/services"
                />
                <meta
                    property="og:image"
                    content="https://houseofwebsites.com/static/media/HowLogo.3d6f3ec8dd4c57827d4f80e8e623f64c.svg"
                />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="House of Websites" />
                <meta property="og:locale" content="en_IN" />
                <meta
                    name="twitter:title"
                    content="Web Design, Branding & SEO Services | HOW"
                />
                <meta
                    name="twitter:description"
                    content="Discover how HOW can help elevate your business with our expert web design, branding, and SEO services. Contact us today for a custom solution!"
                />
                <meta
                    name="twitter:url"
                    content="https://houseofwebsites.com/services"
                />
                <meta
                    name="twitter:image"
                    content="https://houseofwebsites.com/static/media/HowLogo.3d6f3ec8dd4c57827d4f80e8e623f64c.svg"
                ></meta>
            </Helmet>
            <div>
                <ServiceBanner />
                {/* <ServiceTab/> */}
                <ServiceAccordian />
                <ServiceStep />
            </div>
        </>
    )
}

export default Servcies
