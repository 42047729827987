import React from 'react'
import {
    DoubleCircle,
    PurpleBlurBig,
    Team,
    TeamBox,
    TeamCardBox,
    TeamDescription,
    TeamHead,
    TeamOut,
} from '../../teamgroupStyle'
import TeamCard from '../../teamCards'
import { contentWriter } from '../../TeamInfo'
import { Box } from '@mui/material'
import star from '../../../../../asset/images/doublecircle.webp'
import blur from '../../../../../asset/images/purpleblurbig.webp'
import { Images } from '../../../../../asset/images'

function Content() {
    return (
        <>
            <TeamOut>
                <DoubleCircle>
                    <img
                        loading="eager"
                        src={Images.Doublecircle}
                        alt="Doublecircle"
                        style={{ width: '100%' }}
                    />
                </DoubleCircle>
                <PurpleBlurBig>
                    <img
                        loading="eager"
                        src={Images.Purpleblurbig}
                        alt="Purpleblurbig"
                        style={{ width: '100%' }}
                    />
                </PurpleBlurBig>
                <Team>
                    <TeamBox>
                        <TeamHead>Scribble Squad</TeamHead>
                        <TeamDescription>
                            Our content writers weave compelling narratives,
                            transforming ideas into engaging stories that
                            captivate and connect with your audience.
                        </TeamDescription>
                    </TeamBox>
                    <TeamCardBox>
                        {contentWriter.map((data) => (
                            <TeamCard data={data} />
                        ))}
                    </TeamCardBox>
                </Team>
            </TeamOut>
        </>
    )
}

export default Content
