import React from 'react'
import { Grid, Box, Container, Typography, Item, Button } from '@mui/material'

import { Images } from '../../../asset/images'
import {
    DnaHeading,
    DnaSubHeading,
    DnaSubHeading1,
    DnaContent,
    OurvaluesHeading,
    OurvaluesContent,
} from './dna.style'
import './DNA.css'

export default function Aboutus() {
    return (
        <>
            <Grid
                container
                sx={{
                    backgroundImage: `URL(${Images.Dna})`,
                    backgroundSize: 'cover',
                    backgroundRepeat: 'no-repeat',
                }}
            >
                <Box
                    className="container1"
                    sx={{ maxWidth: '1296px', margin: '0 auto' }}
                >
                    <Grid container>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            sx={{
                                padding: {
                                    lg: '7.5rem 0rem 0rem 0rem',
                                    md: '5.5rem 0rem 0rem 0rem',
                                    sm: '2rem 0 0 2rem',
                                    xs: '2rem 1.25rem 0',
                                },
                            }}
                        >
                            <DnaHeading>
                                What is imprinted <br /> in our DNA
                            </DnaHeading>
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            lg={6}
                            sx={{
                                padding: {
                                    lg: '0',
                                    md: '0',
                                    sm: '0 0 1rem 2rem',
                                    xs: '0rem 1.25rem',
                                },
                            }}
                        >
                            <Box sx={{ textAlign: 'center' }}>
                                <Box
                                    component="img"
                                    src={Images.Dnaroate}
                                    alt="creative web agency"
                                    sx={{
                                        width: {
                                            xs: '80%',
                                            sm: '80%',
                                            md: '80%',
                                            lg: '80%',
                                        },
                                        mixBlendMode: 'difference',
                                    }}
                                />
                            </Box>
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            lg={6}
                            sx={{
                                padding: {
                                    lg: '0rem 0rem 1rem 0rem',
                                    md: '0rem 0rem 1rem 0rem',
                                    sm: '0 2rem 1rem 0',
                                    xs: '0 1.25rem 1rem ',
                                },
                            }}
                        >
                            <Box
                                sx={{
                                    textAlign: {
                                        md: 'right',
                                        sm: 'center',
                                        xs: 'center',
                                    },
                                }}
                            >
                                <Box
                                    component="img"
                                    src={Images.SignleLine}
                                    alt="House of websites"
                                    sx={{
                                        width: {
                                            xs: '50%',
                                            sm: '50%',
                                            md: '80%',
                                            lg: '80%',
                                        },
                                        mixBlendMode: 'difference',
                                    }}
                                />
                            </Box>
                            <DnaSubHeading>Mission</DnaSubHeading>
                            <DnaContent
                                sx={{ paddingBottom: '41px' }}
                                variant="body2"
                            >
                                To empower businesses and individuals, including
                                Micro, Small, and Medium Enterprises with the
                                digital tools and strategies they need to thrive
                                in the ever-evolving online landscape.
                            </DnaContent>

                            <DnaSubHeading1>Vision</DnaSubHeading1>
                            <DnaContent variant="body2">
                                To be the architects of the future web, crafting
                                websites that is functional, and transformative.
                                We envision a world where every business has a
                                captivating online presence that ignites user
                                delight and fuels success.
                            </DnaContent>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>

            {/* our values */}
            <Grid container sx={{ backgroundColor: '#F7F7F7' }}>
                <Box
                    className="container1"
                    sx={{ maxWidth: '1296px', margin: '0 auto' }}
                >
                    <Grid container>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={12}
                            lg={12}
                            sx={{ textAlign: 'center' }}
                        >
                            <DnaHeading
                                sx={{
                                    color: '#0B1320 !important',
                                    padding: {
                                        lg: '100px 0px 24px 0px',
                                        md: '75px 0px 24px 0px',
                                        sm: '50px 0px 24px 0px',
                                        xs: '24px 0px 24px 0px',
                                    },
                                }}
                            >
                                Our Values
                            </DnaHeading>
                            <DnaContent
                                variant="body2"
                                sx={{
                                    color: '#000 !important',
                                    marginBottom: '18px',
                                    padding: {
                                        lg: '0px 271px 0px 277px ',
                                        md: '0px 200px 0px 200px',
                                        sm: '0 1rem',
                                        xs: '0 1rem',
                                    },
                                }}
                            >
                                At House of Websites, we are driven by
                                integrity, creativity, and excellence. We
                                believe in building lasting relationships,
                                fostering innovation, and delivering impactful
                                solutions that resonate with both our clients
                                and the world.
                            </DnaContent>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        // spacing={3}
                        columnSpacing={3}
                        sx={{
                            padding: {
                                lg: '0px',
                                md: '0',
                                sm: '0 2rem',
                                xs: '0 1.25rem',
                            },
                        }}
                        className="abouts_growth_cont"
                    >
                        <Grid item xs={12} sm={12} md={7} lg={7}>
                            <Box
                                sx={{
                                    '@media (max-width:900px)': {
                                        marginBottom: '20px',
                                    },
                                }}
                            >
                                <img
                                    loading="eager"
                                    src={Images.Grp}
                                    width={'100%'}
                                    alt="creative web agency"
                                ></img>
                            </Box>
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={5}
                            lg={5}
                            sx={{
                                marginTop: { lg: '34px', md: '27px' },
                            }}
                        >
                            <Box
                                sx={{
                                    '@media (max-width:900px)': {
                                        marginBottom: '20px',
                                    },
                                    backgroundImage: `URL(${Images.OurvalueBg})`,
                                    backgroundSize: { lg: '113%', md: '113%' },
                                    WebkitBackgroundSize: {
                                        sm: '114% 126%',
                                        xs: '114% 126%',
                                    },

                                    backgroundRepeat: 'no-repeat',
                                    height: { lg: '100%', md: '100%' },

                                    padding: {
                                        lg: '30px 25px 0px 32px',
                                        md: '1rem 1rem',
                                        sm: '2rem 2rem',
                                        xs: '1rem 1rem',
                                    },
                                }}
                            >
                                <Box
                                    component="img"
                                    src={Images.OurFlag}
                                    alt="creative web agency"
                                    sx={{
                                        width: {
                                            xs: '10%',
                                            sm: '10%',
                                            md: '10%',
                                            lg: '12%',
                                        },
                                    }}
                                />

                                <OurvaluesHeading>Growth</OurvaluesHeading>
                                <OurvaluesContent
                                    variant="body2"
                                    sx={{
                                        paddingBottom: {
                                            sm: '20px',
                                            xs: '20px',
                                        },
                                    }}
                                >
                                    Continuously evolving, we embrace change and
                                    innovation, driving sustainable growth for
                                    our clients, our team, and the digital
                                    landscape.
                                </OurvaluesContent>
                            </Box>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        sx={{
                            padding: {
                                lg: '0px',
                                md: '0',
                                sm: '0 2rem',
                                xs: '0 1.25rem',
                            },
                        }}
                        // spacing={3}
                        columnSpacing={3}
                    >
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={4}
                            lg={4}
                            sx={{
                                marginTop: { lg: '24px' },
                            }}
                        >
                            <Box
                                sx={{
                                    '@media (max-width:900px)': {
                                        marginBottom: '20px',
                                    },
                                    padding: {
                                        lg: '32px 37px 56px 32px',
                                        md: '1rem 1rem',
                                        sm: '2rem 2rem',
                                        xs: '1rem 1rem',
                                    },
                                    backgroundImage: `URL(${Images.Our_bg_border})`,

                                    backgroundSize: { lg: '100%', md: '100%' },
                                    WebkitBackgroundSize: {
                                        sm: '100%',
                                        xs: '100%',
                                    },
                                    backgroundRepeat: 'no-repeat',
                                    height: { lg: '320px', md: '320px' },
                                }}
                            >
                                <Box
                                    component="img"
                                    src={Images.OurPeople}
                                    alt="digital marketing"
                                    sx={{
                                        width: {
                                            xs: '10%',
                                            sm: '10%',
                                            md: '10%',
                                            lg: '18%',
                                        },
                                    }}
                                />

                                <OurvaluesHeading>People</OurvaluesHeading>

                                <OurvaluesContent variant="body2">
                                    Empowering individuals to thrive, we value
                                    collaboration, respect, and diversity,
                                    ensuring every voice is heard and every idea
                                    is nurtured.
                                </OurvaluesContent>
                            </Box>
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={4}
                            lg={4}
                            sx={{
                                marginTop: { lg: '24px' },
                            }}
                        >
                            <Box
                                sx={{
                                    // '@media (max-width:900px)':{marginBottom:'20px'},
                                    padding: {
                                        lg: '32px 37px 0px 32px',
                                        md: '1rem 1rem',
                                        sm: '2rem 2rem',
                                        xs: '1rem 1rem',
                                    },
                                    backgroundImage: `URL(${Images.Our_bg_border})`,
                                    backgroundSize: { lg: '100%', md: '100%' },
                                    WebkitBackgroundSize: {
                                        sm: '100%',
                                        xs: '100%',
                                    },
                                    backgroundRepeat: 'no-repeat',
                                    height: { lg: '320px', md: '320px' },
                                }}
                            >
                                <Box
                                    component="img"
                                    src={Images.ourAwarness}
                                    alt="digital marketing"
                                    sx={{
                                        width: {
                                            xs: '10%',
                                            sm: '10%',
                                            md: '10%',
                                            lg: '18%',
                                        },
                                    }}
                                />

                                <OurvaluesHeading>Awareness</OurvaluesHeading>
                                <OurvaluesContent variant="body2">
                                    Staying informed and responsive, we
                                    prioritize awareness of trends, client
                                    needs, and social impact, ensuring our
                                    solutions are relevant and responsible.
                                </OurvaluesContent>
                            </Box>
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            sm={12}
                            md={4}
                            lg={4}
                            sx={{
                                '@media (max-width:900px)': {
                                    marginBottom: '24px',
                                },
                                margin: {
                                    lg: '-10px 0px 0px 0px',
                                    md: '-27px 0px 0px 0px',
                                },
                            }}
                        >
                            <Box>
                                <img
                                    loading="eager"
                                    src={Images.OurRm}
                                    width={'100%'}
                                    alt="digital marketing"
                                ></img>
                            </Box>
                        </Grid>
                    </Grid>
                </Box>
            </Grid>
        </>
    )
}
