import React, { useState } from 'react'
import { Box, Typography, Button } from '@mui/material'
import { Images } from '../../../asset/images/index'
import {
    Root,
    ImageContainer,
    Image,
    Container,
    BackButton,
    DetailsBox,
    TitleContainer,
    Title,
    SubInfoContainer,
    SubInfoText,
    ReadMoreText,
    ReadMoreButton,
    GridContainer,
    GridItem,
    DescriptionText,
    SectionTitle,
    Ul,
    ButtonApply,
} from './careers-details-style.js'
import CarrersProposal from './careersProposal/careersProposal.js'
import { useNavigate } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

export default function CareersDetails() {
    const navigate = useNavigate()

    const handleBackClick = () => {
        navigate(-1)
    }

    const [open, setOpen] = useState(false)

    const [isProposalVisible, setIsProposalVisible] = useState(false)

    const ReadMore = ({ children }) => {
        const text = children
        const [isReadMore, setIsReadMore] = useState(true)
        const toggleReadMore = () => {
            setIsReadMore(!isReadMore)
        }

        if (text.length <= 450) {
            return (
                <ReadMoreText sx={{ fontFamily: 'Lufga' }}>{text}</ReadMoreText>
            )
        }

        return (
            <ReadMoreText sx={{ fontFamily: 'Lufga' }}>
                {isReadMore ? text.slice(0, 450) : text}
                <ReadMoreButton
                    onClick={toggleReadMore}
                    className="read-or-hide"
                >
                    {isReadMore ? '...read more' : 'show less'}
                </ReadMoreButton>
            </ReadMoreText>
        )
    }

    const ReadMoreList = ({ items }) => {
        const [isExpanded, setIsExpanded] = useState(false)
        const toggleReadMore = () => {
            setIsExpanded(!isExpanded)
        }

        const visibleItems = isExpanded ? items : items.slice(0, 4)

        return (
            <>
                <Ul>
                    {visibleItems.map((item, index) => (
                        <li style={{ fontFamily: 'Lufga' }} key={index}>
                            {item}
                        </li>
                    ))}
                </Ul>
                {items.length > 4 && (
                    <ReadMoreButton onClick={toggleReadMore}>
                        {isExpanded ? 'show less' : '...read more'}
                    </ReadMoreButton>
                )}
            </>
        )
    }

    const calculateTimeAgo = (postedDate) => {
        const currentDate = new Date()
        const posted = new Date(postedDate)

        const diffYears = currentDate.getFullYear() - posted.getFullYear()
        const diffMonths =
            currentDate.getMonth() - posted.getMonth() + diffYears * 12
        const diffDays = Math.floor(
            (currentDate - posted) / (1000 * 60 * 60 * 24)
        )

        if (diffYears >= 1) {
            return diffYears === 1
                ? `${diffYears} year ago`
                : `${diffYears} years ago`
        } else if (diffMonths >= 1) {
            return diffMonths === 1
                ? `${diffMonths} month ago`
                : `${diffMonths} months ago`
        } else {
            return diffDays === 1
                ? `${diffDays} day ago`
                : `${diffDays} days ago`
        }
    }

    const postedDate = '2024-08-08'
    const timeAgo = calculateTimeAgo(postedDate)

    const handleClickOpen = () => {
        setOpen(true)
    }

    const handleClose = (value) => {
        setOpen(false)
    }

    return (
        <>
            <Helmet>
                <title>Career Details | House Of Website</title>
                <meta
                    name="title"
                    content="Career Details | House Of Website"
                />
                <meta
                    name="description"
                    content="Join our House Of Websites and explore more career opportunities like web development and digital marketing in a dynamic and innovative environment."
                />
                <meta
                    name="keywords"
                    content="careers at House of Websites,
                            job opportunities Chennai,
                            web design jobs,
                            digital marketing careers,
                            join our team"
                />
                <link
                    rel="canonical"
                    href="https://houseofwebsites.com/careers/careers-details"
                />
            </Helmet>
            <Root>
                <ImageContainer>
                    {/* <Image src={Images.CareersDetialsBlur} alt="Careers Details" /> */}
                </ImageContainer>
                <Container>
                    <BackButton onClick={handleBackClick}>
                        <img
                            loading="eager"
                            src={Images.CDArrow}
                            alt="House of websites"
                        />
                        <Typography
                            sx={{ color: 'white', fontFamily: 'Lufga' }}
                        >
                            Back
                        </Typography>
                    </BackButton>
                    <DetailsBox>
                        <TitleContainer>
                            <Title>Full-Stack Developer</Title>
                            <SubInfoContainer>
                                <SubInfoText>Posted {timeAgo}</SubInfoText>
                                {/* <img loading="eager" src={Images.Bookmark} alt='Careers Details' style={{paddingRight: '14px'}} /> */}
                                <img
                                    loading="eager"
                                    src={Images.Share}
                                    alt="House of websites"
                                    style={{
                                        padding: '8px',
                                        border: '1px solid #CFCFCF',
                                        borderRadius: '50%',
                                        marginRight: '14px',
                                        cursor: 'pointer',
                                    }}
                                />
                                <ButtonApply onClick={handleClickOpen}>
                                    Apply
                                </ButtonApply>
                            </SubInfoContainer>
                        </TitleContainer>
                        <GridContainer>
                            <GridItem>
                                <img
                                    loading="eager"
                                    src={Images.Experience}
                                    alt="careers at House of Websites"
                                />
                                <DescriptionText sx={{ fontFamily: 'Lufga' }}>
                                    2 - 5 years
                                </DescriptionText>
                            </GridItem>
                            <GridItem>
                                <img
                                    loading="eager"
                                    src={Images.Rupee}
                                    alt="careers at House of Websites"
                                />
                                <DescriptionText sx={{ fontFamily: 'Lufga' }}>
                                    10 Lacs P.A.
                                </DescriptionText>
                            </GridItem>
                            <GridItem>
                                <img
                                    loading="eager"
                                    src={Images.JobType}
                                    alt="careers at House of Websites"
                                />
                                <DescriptionText sx={{ fontFamily: 'Lufga' }}>
                                    Hybrid
                                </DescriptionText>
                            </GridItem>
                            <GridItem>
                                <img
                                    loading="eager"
                                    src={Images.CDLocation}
                                    alt="careers at House of Websites"
                                />
                                <DescriptionText sx={{ fontFamily: 'Lufga' }}>
                                    Chennai, Tamilnadu, India
                                </DescriptionText>
                            </GridItem>
                        </GridContainer>
                        <Box>
                            <SectionTitle>Job Details:</SectionTitle>
                            <ReadMore>
                                But I must explain to you how all this mistaken
                                idea of denouncing pleasure and praising pain
                                was born and I will give you a complete account
                                of the system, and expound the actual teachings
                                of the great explorer of the truth, the
                                master-builder of human happiness. No one
                                rejects, dislikes, or avoids pleasure itself,
                                because it is pleasure, but because those who do
                                not know how to pursue pleasure rationally
                                encounter consequences that are extremely
                                painful. Nor again is there anyone who loves or
                                pursues or desires to obtain pain of itself,
                                because it is pain, but because occasionally
                                circumstances occur in which toil and pain can
                                procure him some great pleasure. To take a
                                trivial example, which of us ever undertakes
                                laborious physical exercise, except to obtain
                                some advantage from it? But who has any right to
                                find fault with a man who chooses to enjoy a
                                pleasure that has no annoying.
                            </ReadMore>
                        </Box>
                        <Box>
                            <SectionTitle>Job Role:</SectionTitle>
                            <DescriptionText component="div">
                                <ReadMoreList
                                    items={[
                                        'But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system',
                                        'Expound the actual teachings of the great explorer of the truth, the master-builder of human happiness.',
                                        'No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful.',
                                        'Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure.',
                                        'To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man.',
                                        'Who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain that produces no resultant pleasure?',
                                    ]}
                                />
                            </DescriptionText>
                        </Box>
                        <Box>
                            <SectionTitle>Job Requirements:</SectionTitle>
                            <DescriptionText component="div">
                                <ReadMoreList
                                    items={[
                                        'But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system',
                                        'Expound the actual teachings of the great explorer of the truth, the master-builder of human happiness.',
                                        'No one rejects, dislikes, or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences that are extremely painful.',
                                        'Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure.',
                                        'To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man.',
                                        'Who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain that produces no resultant pleasure?',
                                    ]}
                                />
                            </DescriptionText>
                        </Box>
                    </DetailsBox>
                </Container>

                <Box>
                    <CarrersProposal open={open} onClose={handleClose} />
                </Box>
            </Root>
        </>
    )
}
