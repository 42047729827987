import React, { useEffect, useRef } from 'react'
import { Box, Typography } from '@mui/material'
import { Images } from '../../asset/images'
import {
    LandingHeading,
    LandingHeaderColor,
    TextWritterContainer,
    TextWritterSpan,
    LandingBannerHeader,
} from './landing.style'
import Particles from './particles/Particles'
import Bubble from './bubble/Bubble'
import LandingVideo from './landingVideo'
import LandingAccordion from './ladning-accordion'
import LandingCount from './landing-count'
import LandingProjects from './landing-projects'
import LandingClientLove from './landing-clients'
import GetNewTechnologyEmail from './new technology-email/NewTechnology'
import BarLine from './bar-line'
import BlogSection from './blogs'
import { Helmet } from 'react-helmet-async'
import { BlogContainerSection, BlogsText } from './blogs/blog.style'

export default function Landing() {
    // <Helmet>
    //     <title>House of websites</title>
    //     <meta
    //         name="title"
    //         content="House Of Websites: Custom Web Development & Digital Solution"
    //     />
    //     <meta
    //         name="description"
    //         content="Our expertise House Of Website team offers you custom web development and digital solution that can enhance your business in the online world."
    //     />
    //      <meta
    //         name="keywords"
    //         content="creative web agency, House of Websites, web design services, custom website development, digital marketing,web development & digital marketing"
    //     />
    //     <link rel="canonical" href="https://houseofwebsites.com/" />
    // </Helmet>
    return (
        <>
            <Helmet>
                <title>
                    House of Websites | Top Web Development Company in Chennai
                </title>
                <meta
                    name="title"
                    content="House of Websites | Top Web Development Company in Chennai"
                />
                <meta
                    name="description"
                    content="We are top-tier Web Development Company  in Chennai. We create responsive, SEO-friendly websites that drive  business growth. "
                />
                <meta
                    name="keywords"
                    content="creative web agency, House of Websites, web design services, custom website development, digital marketing,web development & digital marketing"
                />
                <link rel="canonical" href="https://houseofwebsites.com/" />
                <meta
                    property="og:title"
                    content="House of Websites | Top Web Development Company in Chennai"
                />
                <meta
                    property="og:description"
                    content="We are top-tier Web Development Company  in Chennai. We create responsive, SEO-friendly websites that drive  business growth. "
                />
                <meta
                    property="og:url"
                    content="https://houseofwebsites.com/"
                />
                <meta
                    property="og:image"
                    content="https://houseofwebsites.com/static/media/HowLogo.3d6f3ec8dd4c57827d4f80e8e623f64c.svg"
                />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="House of Websites" />
                <meta property="og:locale" content="en_IN" />

                <meta name="twitter:card" content="summary_large_image" />
                <meta
                    name="twitter:title"
                    content="House of Websites | Top Web Development Company in Chennai"
                />
                <meta
                    name="twitter:description"
                    content="House of Websites offers top-tier Web Development Company in Chennai. We create responsive, SEO-friendly websites that drive business growth."
                />
                <meta
                    name="twitter:url"
                    content="https://houseofwebsites.com/"
                />
                <meta
                    name="twitter:image"
                    content="https://houseofwebsites.com/static/media/HowLogo.3d6f3ec8dd4c57827d4f80e8e623f64c.svg"
                />
            </Helmet>
            <Box
                sx={{
                    overflowX: 'clip',
                }}
            >
                <Box
                    sx={{
                        marginBottom: '-10px',
                    }}
                >
                    <Box
                        component="img"
                        src={Images.HOWBanner}
                        alt="banner"
                        sx={{
                            width: '100%',
                            height: {
                                xs: '65vh',
                                sm: '75vh',
                                md: '90vh',
                                lg: '100vh',
                            },
                            objectFit: 'cover',
                        }}
                        loading="lazy"
                    />
                    <LandingBannerHeader>
                        {/* <LandingHeading variant='h1'>
                            We transform
                        </LandingHeading>
                        <Typography  variant='h1'  sx={{
                              fontSize: 'clamp(30px, 5vw, 80px)',
                              fontWeight: '300',
                              marginTop: '10px',
                              letterSpacing: '3px',
                              fontDisplay:'swap'
                        }}>
                            
                            powerful visions into
                        </Typography>
                        <LandingHeaderColor  variant='h1'>
                            dynamic websites
                        </LandingHeaderColor> */}
                        <Typography
                            variant="h1"
                            sx={{
                                fontSize: 'clamp(20px, 5vw, 60px)',
                                fontWeight: '300',
                                marginTop: '10px',
                                letterSpacing: '3px',
                                fontDisplay: 'swap',
                            }}
                        >
                            Bring your vision to life with our
                            <LandingHeaderColor>
                                Web Development company & Design agency
                            </LandingHeaderColor>
                        </Typography>
                    </LandingBannerHeader>
                    <Bubble />
                    <Particles />
                </Box>
                <LandingVideo />
            </Box>

            <BarLine />

            <LandingAccordion />

            <LandingProjects />
            <LandingCount />
            <LandingClientLove />
            <Box
                sx={{
                    background: 'rgba(15, 1, 33, 1)',
                    width: '100%',
                }}
            >
                {/* <GetNewTechnologyEmail /> */}

                <BlogContainerSection>
                    <BlogsText>Blogs </BlogsText>

                    {/* <BlogSection /> */}
                </BlogContainerSection>
            </Box>
        </>
    )
}
