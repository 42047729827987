import React from 'react'
import { CuddaloreSupport } from '../../TeamInfo'
import {
    Team,
    TeamBox,
    TeamCardBox,
    TeamDescription,
    TeamHead,
} from '../../teamgroupStyle'
import TeamCard from '../../teamCards'

function CudSupport() {
    return (
        <>
            <Team>
                <TeamBox>
                    <TeamHead>First Responders</TeamHead>
                    <TeamDescription>
                        Our First Responders are the L1 Support team, swiftly
                        addressing and resolving issues with expertise. They
                        ensure seamless operations and top-notch user
                        satisfaction.
                    </TeamDescription>
                </TeamBox>
                <TeamCardBox>
                    {CuddaloreSupport.map((data, index) => (
                        <TeamCard key={index} data={data} />
                    ))}
                </TeamCardBox>
            </Team>
        </>
    )
}

export default CudSupport
