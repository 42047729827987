import React from 'react'
import {
    LeaderDes,
    Team,
    TeamBox,
    TeamCardBox,
    TeamDescription,
    TeamHead,
    TeamOut,
} from '../../teamgroupStyle'
import { leaderObject } from '../../TeamInfo'
import TeamCard from '../../teamCards'
import des from '../../../../../asset/images/boxses.webp'
import { Box } from '@mui/material'
import { Images } from '../../../../../asset/images'

function Leader() {
    return (
        <>
            <TeamOut>
                <LeaderDes>
                    <img
                        loading="eager"
                        src={Images.Boxses}
                        alt="Boxses"
                        style={{ width: '100%' }}
                    />
                </LeaderDes>
                <Team>
                    <TeamBox>
                        <TeamHead>The Helm</TeamHead>
                        <TeamDescription>
                            Our visionary leadership team drives innovation and
                            excellence, guiding House of Websites towards
                            creating outstanding digital solutions. 
                        </TeamDescription>
                    </TeamBox>
                    <TeamCardBox>
                        {leaderObject.map((data) => (
                            <TeamCard data={data} />
                        ))}
                    </TeamCardBox>
                </Team>
            </TeamOut>
        </>
    )
}

export default Leader
