import React from 'react'
import { cuddaloreDesign } from '../../TeamInfo'
import {
    Team,
    TeamBox,
    TeamCardBox,
    TeamDescription,
    TeamHead,
} from '../../teamgroupStyle'
import TeamCard from '../../teamCards'

function CudDesigner() {
    return (
        <>
            <Team>
                <TeamBox>
                    <TeamHead>The Craftsmen</TeamHead>
                    <TeamDescription>
                        Our talented designers craft visually stunning and
                        user-friendly websites, blending creativity and
                        precision for an unmatched digital experience. 
                    </TeamDescription>
                </TeamBox>
                <TeamCardBox>
                    {cuddaloreDesign.map((data, index) => (
                        <TeamCard key={index} data={data} />
                    ))}
                </TeamCardBox>
            </Team>
        </>
    )
}

export default CudDesigner
