import { Box, boxClasses, styled } from '@mui/material'

export const custombuttonLeft = {
    // border:'1px solid rgba(254, 198, 29, 1)',
    backgroundColor: '#000',
    color: '#fff',
    borderRadius: '50%',
    width: 40,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
        backgroundColor: 'rgba(254, 198, 29, 1)',
    },
}
export const custombuttonRight = {
    // border:'1px solid rgba(254, 198, 29, 1)',
    backgroundColor: '#000',
    color: '#fff',
    borderRadius: '50%',
    width: 40,
    height: 40,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '&:hover': {
        backgroundColor: 'rgba(254, 198, 29, 1)',
    },
}

export const btnContainer = {
    position: 'absolute',
    top: '50%',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    transform: 'translateY(-50%)',
    // px: 2,
    zIndex: 10,
    cursor: 'pointer',
    // display: 'none'
}

export const tabStyle = {
    '@media (max-width:500px)': {
        padding: '10px 5px',
    },
}

export const StyledBox = styled(Box)(({ theme }) => ({
    maxWidth: '100%',
    margin: '0px auto',
}))
