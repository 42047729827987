import React from 'react'
import TeamBanner from './teamBanner'
import GroupSlider from './teamGroup'
import { Box } from '@mui/material'
import { Helmet } from 'react-helmet-async'

export default function Ourteam() {
    return (
        <>
            <Helmet>
                <title>Our Team | Meet our crew at House of Websites</title>
                <meta
                    name="title"
                    content="Our Team | Meet our crew at House of Websites"
                />
                <meta
                    name="description"
                    content="Join the House of Websites team! We’re looking for creative talent to grow with us. Apply today and be part of our innovative web design company!"
                />
                <meta
                    name="keywords"
                    content="House of Websites portfolio,
                            Expert Team,
                            our works,
                            web design projects Chennai,
                            website development portfolio,
                            successful web design examples,
                            creative project showcase,
                            digital solutions portfolio "
                />
                <link
                    rel="canonical"
                    href="https://houseofwebsites.com/ourteam"
                />
                <meta
                    property="og:title"
                    content="Our Team | Meet Our Crew at House of Websites"
                />
                <meta
                    property="og:description"
                    content="Join the House of Websites team! We’re looking for creative talent to grow with us. Apply today and be part of our innovative web design company!"
                />
                <meta
                    property="og:url"
                    content="https://houseofwebsites.com/team"
                />
                <meta
                    property="og:image"
                    content="https://houseofwebsites.com/static/media/HowLogo.3d6f3ec8dd4c57827d4f80e8e623f64c.svg"
                />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="House of Websites" />
                <meta property="og:locale" content="en_IN" />
                <meta
                    name="twitter:title"
                    content="Our Team | Meet Our Crew at House of Websites"
                />
                <meta
                    name="twitter:description"
                    content="Join the House of Websites team! We’re looking for creative talent to grow with us. Apply today and be part of our innovative web design company!"
                />
                <meta
                    name="twitter:url"
                    content="https://houseofwebsites.com/team"
                />
                <meta
                    name="twitter:image"
                    content="https://houseofwebsites.com/static/media/HowLogo.3d6f3ec8dd4c57827d4f80e8e623f64c.svg"
                ></meta>
            </Helmet>
            <Box
                sx={{
                    backgroundColor: '#0F0121',
                    fontFamily: 'Lufga',
                }}
            >
                <TeamBanner />
                <GroupSlider />
            </Box>
        </>
    )
}
