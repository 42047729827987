import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import './styles/fonts.css'
import './index.css'
import reportWebVitals from './reportWebVitals'
import { CssBaseline, ThemeProvider } from '@mui/material'
import { theme } from './styles/createTheme'
import { AppProvider } from './context/app.context'
import { HelmetProvider } from 'react-helmet-async'

const root = ReactDOM.createRoot(document.getElementById('root'))
const helmetContext = {}
root.render(
    <React.StrictMode>
        <HelmetProvider>
            <ThemeProvider theme={theme}>
                <AppProvider>
                    <App />
                </AppProvider>
            </ThemeProvider>
        </HelmetProvider>
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
