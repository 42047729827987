import { Box, Typography } from '@mui/material'
import React from 'react'
import { bg, heading, secondHead, secondSecContent, secondSecContent2 } from './style'

const CaseStudySec = () => {
  return (
    <Box sx={bg}>
      <Box sx={{paddingBottom: '50px'}}>
        <Typography sx={heading}>
          Client
        </Typography>
        <Typography sx={secondHead}>
          Service
        </Typography>
        <Typography sx={secondSecContent}>
        Driving innovation isn’t a buzzword, it’s a company tenet. We challenge ourselves to come up with new processes and products, and are constantly improving to satisfy our consumers’ tastes.
        </Typography>
      </Box>
      <Box>
        <Typography sx={heading}>
          Client
        </Typography>
        <Typography sx={secondHead}>
          Service
        </Typography>
        <Typography sx={secondSecContent2}>
        Our goal was to create one cohesive platform that combines Fresh Del Monte’s strengths as a distribution and logistics company, community advocate, and a leader in sustainability. To achieve this, we aimed to change brand perception and be known for more than just pineapples and bananas. We also wanted to eliminate the GMO stigma and improve communication through this platform: a website that shows how the brand deeply understands their consumers’ needs and fulfills retailers’ curiosities.
        </Typography>
      </Box>
    </Box>
  )
}

export default CaseStudySec
