import styled from '@emotion/styled'
import {
    Accordion,
    Box,
    Button,
    LinearProgress,
    Typography,
} from '@mui/material'
import { Images } from '../../asset/images'

export const LandingHeading = styled(Typography)(({ theme }) => ({
    fontSize: 'clamp(30px, 5vw, 80px)',
    fontWeight: '300',
    marginTop: '10px',
    letterSpacing: '3px',
    // padding: '0 4rem',

    // [theme.breakpoints.down('sm')]: {
    //     textAlign:'center',
    //     padding:'0 4rem',
    //     background:'red'
    // },
}))

export const LandingBannerHeader = styled(Box)(({ theme }) => ({
    color: 'white',
    zIndex: 1,
    position: 'absolute',
    top: '0',
    maxWidth: '1296px',
    margin: '0 auto',
    left: 0,
    right: 0,
    marginTop: '100px',
    padding: '0 4.5rem',

    [theme.breakpoints.down('sm')]: {
        padding: '0 20px',
        textAlign: 'center',
    },
    [theme.breakpoints.down('md')]: {
        padding: '0 2rem',
    },

    [theme.breakpoints.up('sm')]: {
        marginTop: '150px',
    },
    [theme.breakpoints.up('md')]: {
        marginTop: '150px',
    },
    [theme.breakpoints.up('lg')]: {
        marginTop: '200px',
    },
}))

export const LandingHeaderColor = styled(Typography)(({ theme }) => ({
    fontSize: 'clamp(20px, 5vw, 60px)',
    backgroundImage:
        'linear-gradient(to right, rgba(248, 58, 58, 1), rgba(241, 61, 212, 1), rgba(248, 58, 58, 1))',
    backgroundClip: 'text',
    WebkitBackgroundClip: 'text',
    color: 'transparent',
    fontWeight: 700,
    marginTop: '10px',
    letterSpacing: '5px',
    width: '100%',
    [theme.breakpoints.down('md')]: {
        width: '100%',
    },
}))

export const LandingImg = styled('img')(({ theme }) => ({
    position: 'relative',
    width: '100%',
    height: '100%',
    backgroundImage: `url(${Images.HOWBanner})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
}))

export const TextWritterSpan = styled(Typography)(({ theme }) => ({
    fontSize: '30px',
    fontWeight: 'bold',
}))

export const LandingAccordionContainer = styled(Accordion)(({ theme }) => ({
    background: 'transparent',
    color: 'white',
    boxShadow: 'none',
}))

export const BorderLinearProgress = styled(LinearProgress)(
    ({ customcolor, ...rest }) => ({
        height: 2, // Custom height of the progress bar
        borderRadius: 5, // Rounded corners
        backgroundColor: 'rgba(56, 55, 55, 1)',
        '& .MuiLinearProgress-bar': {
            backgroundColor: customcolor || 'defaultColor',
        },
    })
)

export const StyledVideo = styled('video')(({ theme }) => ({
    width: '100%',
    height: 'auto',
    borderRadius: '10px',
    marginTop: '-2.8rem',
    '@media (max-width: 600px)': {
        marginTop: '-1.8rem',
    },
}))

export const thumbnailImage = {
    width: '100%',
    height: '90%',
    borderRadius: '10px',
    marginTop: '-5.8rem',
    '@media (max-width: 600px)': {
        marginTop: '-1.8rem',
    },
}
