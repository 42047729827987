import React from 'react'
import {
    CircleBlur,
    GreenBlur,
    Team,
    TeamBox,
    TeamCardBox,
    TeamDescription,
    TeamHead,
    TeamOut,
} from '../../teamgroupStyle'
import { analistObject } from '../../TeamInfo'
import TeamCard from '../../teamCards'
import des from '../../../../../asset/images/cubes.webp'
import blur from '../../../../../asset/images/green blur.webp'
import { Box } from '@mui/material'
import { Images } from '../../../../../asset/images'

function Analist() {
    return (
        <>
            <TeamOut>
                <CircleBlur>
                    <img
                        loading="eager"
                        src={Images.Cubes}
                        style={{ width: '100%' }}
                        alt="cubes"
                    />
                </CircleBlur>
                <GreenBlur>
                    <img
                        loading="eager"
                        src={Images.Greenblur}
                        style={{ width: '100%' }}
                        alt="Greenblur"
                    />
                </GreenBlur>

                <Team>
                    <TeamBox>
                        <TeamHead>Requirement Rangers</TeamHead>
                        <TeamDescription>
                            Our business analysts expertly bridge client needs
                            and technical solutions, ensuring every project is
                            perfectly aligned with your goals.
                        </TeamDescription>
                    </TeamBox>
                    <TeamCardBox>
                        {analistObject.map((data) => (
                            <TeamCard data={data} />
                        ))}
                    </TeamCardBox>
                </Team>
            </TeamOut>
        </>
    )
}

export default Analist
