import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { BASE_URL } from '../../../constants/config'
import Notfound from '../../404'

const SingleBlog = () => {
    const { slug } = useParams()
    const [post, setPost] = useState(null)

    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(null)

    // useEffect(() => {
    //     const fetchPost = async () => {
    //         try {
    //             const url = `${BASE_URL}/wp-json/wp/v2/posts?slug=${slug}`;
    //             const response = await axios.get(url);

    //             // Check if the response data is valid
    //             if (response.data.length === 0) {
    //                 setError('Post not found');
    //             } else {
    //                 setPost(response.data[0]);
    //             }
    //         } catch (error) {
    //             setError('Error fetching post');
    //         } finally {
    //             setLoading(false);
    //         }
    //     };

    //     fetchPost();
    // }, [slug]);

    if (loading) return <p>Loading...</p>
    if (error || !post) return <Notfound />

    // const postContent = post.content.rendered;

    // const featuredImage = post._embedded?.['wp:featuredmedia']?.[0]?.source_url;

    return (
        <div className="single-post-container">
            {/* <img loading="eager" src={featuredImage} alt={post.title.rendered} />

            <h1 className='text-lg font-bold'>{post.title.rendered}</h1>
            <h1>Blog ....</h1> */}

            {/* <div className="elementor-content" dangerouslySetInnerHTML={{ __html: postContent }}></div> */}
        </div>
    )
}

export default SingleBlog
