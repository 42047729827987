import React, { useState } from 'react'
import { Tabs } from '@mui/base/Tabs'
import { TabsList } from '@mui/base/TabsList'
import { TabPanel } from '@mui/base/TabPanel'
import { Tab } from '@mui/base/Tab'
import { Box, styled } from '@mui/material'
import { ServicesTab } from './servicesTabStyle'
import ResearchTab from './Research'
import DesginTab from './Design'
import CodeTab from './Code'

function ServiceTab() {
    const [active, setActive] = useState(1)

    const CustomTabList = styled(TabsList)(({ theme }) => ({
        '& .MuiTabs-indicator': {
            backgroundColor: 'rgba(254, 198, 29, 1)',
        },
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '70px',

        button: {
            backgroundColor: 'transparent',
            border: 'none',
            fontFamily: 'Dystopian',

            fontWeight: '700',
            fontSize: '64px',
            color: '#777777',
            [theme.breakpoints.down('md')]: {
                fontSize: '48px', // Adjust font size for medium screens
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '22px', // Adjust font size for small screens
            },
            [theme.breakpoints.down('xs')]: {
                fontSize: '18px', // Adjust font size for small screens
            },
        },
    }))

    const activeStyle = {
        border: '1px solid #fff',
        width: '20%',
        marginLeft: '7px',
    }

    const handleTabChange = (event, newValue) => {
        setActive(newValue) // Updates the active state when a tab is clicked
    }
    return (
        <>
            <Box sx={{ backgroundColor: '#070707' }}>
                <Box
                    sx={{
                        maxWidth: '1135px',
                        margin: '0 auto',
                        padding: {
                            md: '100px 72px',
                            sm: '50px 2rem',
                            xs: '50px 20px',
                        },
                    }}
                >
                    <Tabs defaultValue={active} onChange={handleTabChange}>
                        <CustomTabList>
                            <Box>
                                <Tab
                                    value={1}
                                    style={{
                                        color:
                                            active === 1 ? '#fff' : '#777777',
                                        fontWeight:
                                            active === 1 ? 'bold' : 'normal',
                                        cursor: 'pointer',
                                    }}
                                >
                                    Research
                                </Tab>
                                {active == 1 ? (
                                    <div style={activeStyle}></div>
                                ) : (
                                    <></>
                                )}
                            </Box>

                            <Box>
                                <Tab
                                    value={2}
                                    style={{
                                        color:
                                            active === 2 ? '#fff' : '#777777',
                                        fontWeight:
                                            active === 2 ? 'bold' : 'normal',
                                        cursor: 'pointer',
                                    }}
                                >
                                    Design
                                </Tab>
                                {active == 2 ? (
                                    <div style={activeStyle}></div>
                                ) : (
                                    <></>
                                )}
                            </Box>

                            <Box>
                                <Tab
                                    value={3}
                                    style={{
                                        color:
                                            active === 3 ? '#fff' : '#777777',
                                        fontWeight:
                                            active === 3 ? 'bold' : 'normal',
                                        cursor: 'pointer',
                                    }}
                                >
                                    Code
                                </Tab>
                                {active == 3 ? (
                                    <div style={activeStyle}></div>
                                ) : (
                                    <></>
                                )}
                            </Box>
                        </CustomTabList>

                        <TabPanel value={1}>
                            <ResearchTab />
                        </TabPanel>
                        <TabPanel value={2}>
                            <DesginTab />
                        </TabPanel>
                        <TabPanel value={3}>
                            <CodeTab />
                        </TabPanel>
                    </Tabs>
                </Box>
            </Box>
        </>
    )
}

export default ServiceTab
