import React from 'react'

import { cuddaloreSEO } from '../../TeamInfo'
import {
    DoubleCircle,
    PurpleBlurBig,
    Team,
    TeamBox,
    TeamCardBox,
    TeamDescription,
    TeamHead,
    TeamOut,
} from '../../teamgroupStyle'
import TeamCard from '../../teamCards'
import star from '../../../../../asset/images/doublecircle.webp'
import blur from '../../../../../asset/images/purpleblurbig.webp'
import { Images } from '../../../../../asset/images'

function CudSEO() {
    return (
        <>
            <TeamOut>
                <DoubleCircle>
                    <img
                        loading="eager"
                        src={Images.Doublecircle}
                        style={{ width: '100%' }}
                        alt="House of Websites"
                    />
                </DoubleCircle>
                <PurpleBlurBig>
                    <img
                        loading="eager"
                        src={Images.Purpleblurbig}
                        style={{ width: '100%' }}
                        alt="House of Websites"
                    />
                </PurpleBlurBig>
                <Team>
                    <TeamBox>
                        <TeamHead>Visibility Virtuosos</TeamHead>
                        <TeamDescription>
                            Meet our Visibility Virtuosos, the SEO Executives at
                            House of Websites. They masterfully optimize
                            strategies to enhance search rankings and drive
                            organic growth.
                        </TeamDescription>
                    </TeamBox>
                    <TeamCardBox>
                        {cuddaloreSEO.map((data, index) => (
                            <TeamCard key={index} data={data} />
                        ))}
                    </TeamCardBox>
                </Team>
            </TeamOut>
        </>
    )
}

export default CudSEO
