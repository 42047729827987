import { Container } from '@mui/material'
import React from 'react'
import AboutusBanner from './about-banner'
import AboutusOurStory from './our-story'

import Director from './director'

import Dna from './DNA'
import { Helmet, HelmetProvider } from 'react-helmet-async'

export default function Aboutus() {
    return (
        <HelmetProvider>
            <Helmet>
                <title>
                    About Us | Custom Web design, Web Development & SEO service
                </title>
                <link
                    rel="canonical"
                    href="https://houseofwebsites.com/aboutus"
                />
                <meta title="About Us | Custom Web design, Web Development & SEO service" />

                <meta
                    name="description"
                    content="House Of Website was orginate at Cuddalore with team of passionate innovators provides you custom web designs and development solutions for your organization."
                    data-rh="true"
                />
                <meta
                    name="keyword"
                    content="web design company in Chennai,
        about our web agency,
        who we are,
        web development team,
        digital marketing experts,
        our mission and vision,
        creative design agency,
        web solutions provider,
        Chennai web design experts,
        professional web designers,
        innovative web development"
                />
                <meta
                    property="og:title"
                    content="About Us | Best Web Development Company for Custom Solutions"
                />
                <meta
                    property="og:description"
                    content="House of Websites blends creative flair with technological expertise to transform business ideas into dynamic online experiences. Our dedication to quality and innovation drives us to push boundaries and create standout digital solutions."
                />
                <meta
                    property="og:url"
                    content="https://houseofwebsites.com/about-us"
                />
                <meta
                    property="og:image"
                    content="https://houseofwebsites.com/static/media/HowLogo.3d6f3ec8dd4c57827d4f80e8e623f64c.svg"
                />
                <meta property="og:type" content="website" />
                <meta property="og:site_name" content="House of Websites" />
                <meta property="og:locale" content="en_IN" />
                <meta name="twitter:card" content="summary_large_image" />
                <meta
                    name="twitter:title"
                    content="About Us | Best Web Development Company for Custom Solutions"
                />
                <meta
                    name="twitter:description"
                    content="Elevate your online presence with the best web development company. We specialize in custom websites that help businesses grow. Start your project today!"
                />
                <meta
                    name="twitter:url"
                    content="https://houseofwebsites.com/about-us"
                />
                <meta
                    name="twitter:image"
                    content="https://houseofwebsites.com/static/media/HowLogo.3d6f3ec8dd4c57827d4f80e8e623f64c.svg"
                ></meta>
            </Helmet>

            <Container disableGutters maxWidth={false} style={{ padding: 0 }}>
                <AboutusBanner />
                <Director />
                <AboutusOurStory />
                <Dna />
            </Container>
        </HelmetProvider>
    )
}
