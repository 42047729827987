import React from "react"
import { PrimaryCustomButton } from "./styled"


const PrimaryButton = ({ style, children, onClick, disable }) => {
  return (
    <PrimaryCustomButton onClick={onClick} extrastyle={style} disabled={disable ?? false}>
      {children}
    </PrimaryCustomButton>
  )
}


export default PrimaryButton
