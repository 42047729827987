import React, { useRef, useState } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/css/navigation'
import 'swiper/css'
import {
    btnContainer,
    custombuttonLeft,
    custombuttonRight,
    StyledBox,
    GlassdoorImg,
    GridContainer,
} from './glassdoorslider.style'
import { Box, Grid, Typography } from '@mui/material'
import { Autoplay, Navigation } from 'swiper/modules'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Glassdoordata } from '../../../../constants'

function Glassdoorslider() {
    const swiperRef = useRef(null)
    const [activeIndex, setActiveIndex] = useState(0)

    const handlePrevClick = () => {
        swiperRef.current.swiper.slidePrev()
    }

    const handleNextClick = () => {
        swiperRef.current.swiper.slideNext()
    }

    const handleClick = (index) => {
        swiperRef.current.swiper.slideToLoop(index)
        setActiveIndex(index)
    }

    const handleSlideChange = (swiper) => {
        const index = swiper.realIndex
        setActiveIndex(index)
    }
    return (
        <Box sx={{ maxWidth: '1253px', margin: '0px auto' }}>
            <Swiper
                className="mySwiperGlassdoor"
                ref={swiperRef}
                slidesPerView={1}
                spaceBetween={30}
                loop={true}
                pagination={false}
                navigation={false}
                modules={[Navigation]}
                onSlideChange={handleSlideChange}
                //  autoplay={{
                //   delay: 3000,
                //   disableOnInteraction: false,
                // }}
            >
                {Glassdoordata.map((img, index) => (
                    <SwiperSlide key={index}>
                        <StyledBox>
                            {/* <Grid container spacing={2}>
                  <Grid justifyContent="center" alignItems="center" flexBasis="50%" flexDirection={'column'} xl={6} lg={6} md={6} sm={12} xs={12}
                    sx={{ display: { md: "flex", xs: "block" }, flexBasis: { md: "50%", xs: "100%" } }}>
                    <GlassdoorImg>
                      <Box component="img" src={img.slideimg} alt={img.imgAlt} sx={{ width: "100%", minHeight: { md: "auto", sm: "415px" } }}></Box>

                    </GlassdoorImg>
                    <Typography sx={{ fontSize: { xl: "64px", lg: "64px", md: "40px", sm: "30px", xs: "30px" }, fontFamily: "Dystopian", fontWeight: "700", color: "#fff", marginTop: "10px", marginBottom: { md: "100px", sm: "80px", xs: "0" }, width: "100%", display: "flex", justifyContent: "flex-end", paddingRight: {xl: "80px", lg: "80px", md : "80px", sm: "80px"} }} data-aos="fade-up" data-aos-duration="1800">Feel it here</Typography>
                  </Grid>
                  <Grid pt={5} xl={6} lg={6} md={6} sm={12} xs={12}>
                    <Box sx={{ width: { md: "5%", xs: "12%" }, marginBottom: "15px", marginLeft: { md: 0, xs: "20px" }, paddingLeft: { md: 0, xs: "0" } }} data-aos="fade-up" data-aos-duration="1400" >
                      <img loading="eager" src={img.quoteimg} style={{width: "100%"}} alt='img' />
                    </Box>
                    <Typography sx={{ fontSize: { xl: "24px", lg: "24px", md: "20px", sm: "16px", xs: "18px" }, fontFamily: "Lufga", fontWeight: "300", color: "#fff", lineHeight: { xl: "40px", lg: "40px", md: "32px", sm: "32px" }, marginBottom: "24px", width: { xl: "550px", lg: "98%", md: "98%", sm: "unset" }, paddingLeft: { md: 0, xs: "20px" } }} data-aos="fade-up" data-aos-duration="2000" >{img.desc}</Typography>
                    <Typography variant="h4" sx={{ fontSize: "24px", fontFamily: "Dystopian", fontWeight: "700", color: "#FEC61D", marginBottom: "12px", paddingLeft: { md: 0, xs: "20px" } }} data-aos="fade-left" data-aos-duration="2100" >Sureshkumar S</Typography>
                    <Typography variant="h4" sx={{ fontSize: "20px", fontFamily: "Lufga", fontWeight: "500", color: "#fff", paddingLeft: { md: 0, xs: "20px" } }} data-aos="fade-left" data-aos-duration="2100">Senior Developer, House of Websites</Typography>

                  </Grid>
                </Grid> */}

                            <GridContainer>
                                <Box
                                    justifyContent="center"
                                    alignItems="center"
                                    flexBasis="50%"
                                    flexDirection={'column'}
                                    xl={6}
                                    lg={6}
                                    md={6}
                                    sm={12}
                                    xs={12}
                                    sx={{
                                        display: { md: 'flex', xs: 'block' },
                                        flexBasis: { md: '50%', xs: '100%' },
                                    }}
                                >
                                    <GlassdoorImg>
                                        <Box
                                            component="img"
                                            src={img.slideimg}
                                            alt={img.imgAlt}
                                            sx={{
                                                width: '100%',
                                                minHeight: {
                                                    md: 'auto',
                                                    sm: '415px',
                                                },
                                            }}
                                        ></Box>
                                    </GlassdoorImg>
                                    <Typography
                                        sx={{
                                            fontSize: {
                                                xl: '64px',
                                                lg: '64px',
                                                md: '40px',
                                                sm: '30px',
                                                xs: '30px',
                                            },
                                            fontFamily: 'Dystopian',
                                            fontWeight: '700',
                                            color: '#fff',
                                            marginTop: '10px',
                                            marginBottom: {
                                                md: '100px',
                                                sm: '80px',
                                                xs: '0',
                                            },
                                            width: '100%',
                                            display: 'flex',
                                            justifyContent: 'flex-end',
                                            paddingRight: {
                                                xl: '80px',
                                                lg: '80px',
                                                md: '80px',
                                                sm: '80px',
                                            },
                                        }}
                                        data-aos="fade-up"
                                        data-aos-duration="1800"
                                    >
                                        Feel it here
                                    </Typography>
                                </Box>
                                <Box
                                    sx={{
                                        paddingTop: {
                                            xl: '40px',
                                            lg: '40px',
                                            md: '20px',
                                            sm: '0',
                                            xs: '30px',
                                        },
                                    }}
                                    xl={6}
                                    lg={6}
                                    md={6}
                                    sm={12}
                                    xs={12}
                                >
                                    <Box
                                        sx={{
                                            width: { md: '5%', xs: '12%' },
                                            marginBottom: '15px',
                                            marginLeft: { md: 0, xs: '20px' },
                                            paddingLeft: { md: 0, xs: '0' },
                                        }}
                                        data-aos="fade-up"
                                        data-aos-duration="1400"
                                    >
                                        <img
                                            loading="eager"
                                            src={img.quoteimg}
                                            style={{ width: '100%' }}
                                            alt="quoteimg"
                                        />
                                    </Box>
                                    <Typography
                                        sx={{
                                            fontSize: {
                                                xl: '24px',
                                                lg: '24px',
                                                md: '20px',
                                                sm: '16px',
                                                xs: '18px',
                                            },
                                            fontFamily: 'Lufga',
                                            fontWeight: '300',
                                            color: '#fff',
                                            lineHeight: {
                                                xl: '40px',
                                                lg: '40px',
                                                md: '32px',
                                                sm: '32px',
                                            },
                                            marginBottom: '24px',
                                            width: {
                                                xl: '550px',
                                                lg: '98%',
                                                md: '98%',
                                                sm: 'unset',
                                            },
                                            paddingLeft: { md: 0, xs: '20px' },
                                        }}
                                        data-aos="fade-up"
                                        data-aos-duration="2000"
                                    >
                                        {img.desc}
                                    </Typography>
                                    <Typography
                                        variant="h4"
                                        sx={{
                                            fontSize: '24px',
                                            fontFamily: 'Dystopian',
                                            fontWeight: '700',
                                            color: '#FEC61D',
                                            marginBottom: '12px',
                                            paddingLeft: { md: 0, xs: '20px' },
                                        }}
                                        data-aos="fade-left"
                                        data-aos-duration="2100"
                                    >
                                        {img.empname}
                                    </Typography>
                                    <Typography
                                        variant="h4"
                                        sx={{
                                            fontSize: '20px',
                                            fontFamily: 'Lufga',
                                            fontWeight: '500',
                                            color: '#fff',
                                            paddingLeft: { md: 0, xs: '20px' },
                                        }}
                                        data-aos="fade-left"
                                        data-aos-duration="2100"
                                    >
                                        {img.emprole}
                                    </Typography>
                                </Box>
                            </GridContainer>
                        </StyledBox>
                    </SwiperSlide>
                ))}
            </Swiper>
            <Box sx={btnContainer}>
                <Box onClick={handlePrevClick} sx={custombuttonLeft}>
                    <ArrowBackIcon />
                </Box>
                <Box onClick={handleNextClick} sx={custombuttonRight}>
                    <ArrowForwardIcon />
                </Box>
            </Box>
        </Box>
    )
}

export default Glassdoorslider
