import React from "react";
import CaseStudySec from "./caseStudySec";
import { overallbg, firstSection } from "./style";
import { Box } from "@mui/material";

 function CaseStudy ()  {
    return (
      <Box sx={overallbg}>
         <Box sx={firstSection}>
            <h1 style={{margin: '0'}}>Case Study</h1>
         </Box>
         <CaseStudySec />
      </Box>
    )
 }

export default CaseStudy;