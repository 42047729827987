import { Images } from "../../../asset/images";

export const bg = {
    background: `url(${Images.Grid})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    height: '100vh',
    padding: '100px 72px',
    '@media (max-width:768px)' : {
      fontSize:'44px'
    },
    '@media (max-width:500px)' : {
      fontSize:'28px'
    },
}

export const heading = {
    fontFamily: '',
    fontSize: '40px',
    fontWeight: '700',
    paddingBottom: '16px',
}

export const secondHead = {
    fontFamily: 'Lufga',
    paddingBottom: '32px',
    fontSize: '18px',
}

export const secondSecContent = {
    maxWidth: '951px',
    fontWeight: '500',
    fontSize: '24px',
    fontFamily: 'Lufga',
}

export const secondSecContent2 = {
    maxWidth: '951px',
    fontWeight: '300',
    fontSize: '18px',
    fontFamily: 'Lufga',
}