import styled from '@emotion/styled'
import { Box, Typography } from '@mui/material'

// LandingProjects styles
export const ProjectContainer = styled('div')(({ theme }) => ({
    backgroundColor: '#0F0121',
    position: 'relative',
    padding: '3rem 1rem',
    [theme.breakpoints.down('md')]: {
        padding: '2rem',
    },
    [theme.breakpoints.down('sm')]: {
        padding: '2rem 1.25rem',
    },
    [theme.breakpoints.down('xs')]: {
        padding: '2rem 1.25rem',
    },
}))

export const ProjectsTitle = styled(Typography)(({ theme }) => ({
    fontFamily: 'Dystopian',
    fontSize: '4rem',
    fontWeight: 700,
    color: theme?.palette?.lightWhiteColor,
    textAlign: 'center',
    padding: '0 2rem',

    [theme.breakpoints.down('md')]: {
        fontSize: '3rem',
    },
    [theme.breakpoints.down('sm')]: {
        fontSize: '1.8rem',
        padding: 0,
    },
    [theme.breakpoints.down('xs')]: {
        fontSize: '1rem',
        padding: 0,
    },
}))

export const ProjectsBackground = styled('span')(({ theme }) => ({
    fontWeight: 700,
    backgroundColor: theme?.palette?.yellowColor,
    color: theme?.palette?.lightBlack,
    display: 'inline',
    fontFamily: 'Dystopian',
    fontSize: '4rem',
    paddingBottom: '3px',

    [theme.breakpoints.down('md')]: {
        fontSize: '3rem',
    },
    [theme.breakpoints.down('sm')]: {
        fontSize: '1.8rem',
        padding: 0,
    },
    [theme.breakpoints.down('xs')]: {
        fontSize: '1rem',
        padding: 0,
        background: 'red',
    },
}))

export const ProjectsBgImg = styled('div')(({ theme }) => ({
    img: {
        width: '80%',
        position: 'absolute',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        left: '50%',
        transform: 'translate(-50%, 18%)',
    },
}))

export const ProjectsGrid = styled('div')(({ theme }) => ({
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr)',
    // gap: '7rem',
    justifyItems: 'center',
    position: 'relative',
    maxWidth: '996px',
    margin: '0 auto',
    paddingTop: '5rem',
    '& > div:nth-of-type(even)': {
        marginTop: '150px',
    },

    [theme.breakpoints.down('lg')]: {
        gridTemplateColumns: 'repeat(2, 1fr)',
        gap: '5rem',
    },
    [theme.breakpoints.down('md')]: {
        gridTemplateColumns: '1fr',
        gap: '4rem',
        paddingTop: '3rem',
        '& > div:nth-of-type(even)': {
            marginTop: '0px', // or any value that suits your design
        },
    },
    [theme.breakpoints.down('sm')]: {
        // display:'inline',

        gap: '4rem',
        paddingTop: '3rem',
        '& > div:nth-of-type(even)': {
            marginTop: '0px', // or any value that suits your design
        },
    },
    [theme.breakpoints.down('xs')]: {
        gap: '1rem',
        // display:'inline',
        paddingTop: '4rem',
        '& > div:nth-of-type(even)': {
            marginTop: '0px', // or any value that suits your design
        },
    },
}))

export const UniqueImageSection = styled('div')({
    position: 'relative',
    textAlign: 'center',
    // background:'red',

    '@media (max-width: 767px)': {
        marginTop: '20px',
    },
})

export const UniqueImage = styled(Box)({
    // width: '220px',
    // height: 'auto',
    // '@media (max-width: 600px)': {
    //   width: '180px',
    // },
})

export const UniqueContent = styled(Box)(({ theme }) => ({
    position: 'absolute',
    top: '16%',
    transform: 'translate(25px, 0px)',
    color: 'white',
    padding: '1.5rem',
    textAlign: 'left',
    right: '75px',
    [theme.breakpoints.down('md')]: {
        right: '30px',
    },
    [theme.breakpoints.down('sm')]: {
        top: '20%',
    },
    [theme.breakpoints.down('xs')]: {
        top: '10%',
    },
}))

export const UniqueContentImg = styled('div')({
    img: {
        width: '30px',
        height: 'auto',
    },
})

export const SeeAllProject = styled(Typography)(({ theme }) => ({
    fontFamily: 'Lufga',
    margin: '0',
    color: 'rgba(63, 23, 182, 1)',
    marginBottom: '7px',
    fontSize: '24px',
    fontWeight: 700,
    textWrap: 'noWrap',

    [theme.breakpoints.down('md')]: {
        fontSize: '20px',
    },
    [theme.breakpoints.down('sm')]: {
        fontSize: '18px',
    },
    [theme.breakpoints.down('xs')]: {
        fontSize: '16px',
    },
}))

// Project cards styles

export const ProjectCardContainer = styled('div')(({ theme }) => ({
    maxWidth: '406px',
    position: 'relative',
    transition: 'transform 1s, opacity 1s',
    // '@media (max-width: 460px)': {
    //   height: '520px',
    // },
    '@media (max-width: 900px)': {
        paddingBottom: '68px',
    },
    '&:hover': {
        cursor: 'pointer',
        '.project-overlay': {
            opacity: 1,
        },
        '.project-title': {
            color: '#00E5FD',
        },
    },
}))

export const ProjectImage = styled('img')(({ theme }) => ({
    width: '100%',
    objectFit: 'cover',
    height: '450px',
    [theme.breakpoints.down('sm')]: {
        height: 'auto',
    },
    cursor: 'none',
}))

export const ProjectInfo = styled('div')(({ theme }) => ({
    position: 'absolute',
    color: 'white',
    zIndex: 2,
    paddingTop: '1rem',
    [theme.breakpoints.down('sm')]: {
        marginTop: '5px',
    },
    [theme.breakpoints.down('xs')]: {
        marginTop: '5px',
    },
}))

export const ProjectTitle = styled(Typography)(({ theme }) => ({
    fontSize: '28px',
    fontWeight: 'bold',
    [theme.breakpoints.down('sm')]: {
        fontSize: '20px',
    },
    [theme.breakpoints.down('xs')]: {
        fontSize: '20px',
    },
}))

export const ProjectDescription = styled(Typography)(({ theme }) => ({
    fontSize: '16px',
    fontWeight: 300,
    fontFamily: 'Lufga',
    [theme.breakpoints.down('sm')]: {
        fontSize: '15px',
    },
    [theme.breakpoints.down('xs')]: {
        fontSize: '15px',
    },
}))

export const ProjectOverlay = styled('div')(({ theme }) => ({
    position: 'absolute',
    top: '25%',
    left: '88%',
    opacity: 0, // Initially hidden
    transition: 'opacity 0.3s ease-in-out',
    zIndex: 1,
    cursor: 'pointer',

    '&:hover': {
        opacity: 1,
    },
    [theme.breakpoints.down('sm')]: {
        left: '170px',
    },
    [theme.breakpoints.down('xs')]: {
        left: '170px',
    },
}))

export const OverlayImage = styled('img')({
    width: '90px',
    height: 'auto',
})
