// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import { Link } from 'react-router-dom';

// const BlogSection = () => {
//     const [posts, setPosts] = useState([]);

//     useEffect(() => {
//         let url = 'http://localhost/houseofwebsite/backend/wp-json/wp/v2/posts?_embed';
//         axios.get(url).then((res) => {
//             setPosts(res.data);
//         });
//     }, []);

//     console.log(posts,"postsposts");

//     return (

//         <>
//             <div className='w-4/5 py-3 m-auto flex justify-between align-middle flex-wrap gap-5'>
//                 {
//                     posts && posts.map((post) => {
//                         const featuredImage = post._embedded?.['wp:featuredmedia']?.[0]?.source_url;

//                         return (
//                             <div key={post.id} className='card p-3 w-[350px] shadow-lg rounded-lg flex gap-5 flex-col'>
//                                 <img loading="eager" src={featuredImage} alt={post.title.rendered} />
//                                 <h2 className='text-lg font-bold' style={{color:'white'}}>{post.title.rendered}</h2>
//                                 <p dangerouslySetInnerHTML={{ __html: post.excerpt.rendered }} style={{color:'white'}}></p>
//                                   {/* Read More Button */}
//                     <Link to={`/dashboard/${post.slug}`}>
//                         <button className="text-white px-3 py-2 rounded-lg">Read More</button>
//                     </Link>
//                             </div>
//                         );
//                     })
//                 }
//             </div>
//         </>
//     );
// }

// export default BlogSection

import { Box, Card, CardContent, Grid, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import {
    BlogCardContainer,
    BlogContainerSection,
    BlogContentDateName,
    BlogDescription,
    BlogsText,
    BlogTitle,
    ContentContainer,
    DesignReadStyle,
    GradientBorderWrapper,
} from './blog.style'
import { Images } from '../../../asset/images'
import { BlogSections } from '../../../constants'
import axios from 'axios'
import { BASE_URL } from '../../../constants/config'

export default function BlogSection() {
    // const [posts, setPosts] = useState([]);
    // useEffect(() => {
    //     let url = 'https://houseofwebsites.com/admin/wp-json/wp/v2/posts?_embed';
    //     axios.get(url).then((res) => {
    //         setPosts(res.data);
    //     });
    // }, []);
    // console.log(posts, "posstsss");
    const [posts, setPosts] = useState([])
    const [error, setError] = useState(null)

    // useEffect(() => {
    //     const controller = new AbortController()

    //     const fetchPosts = async () => {
    //         try {
    //             let url = `${BASE_URL}/wp-json/wp/v2/posts?_embed`
    //             const response = await axios.get(url, {
    //                 signal: controller.signal,
    //             })
    //             setPosts(response.data)
    //         } catch (err) {
    //             if (axios.isCancel(err)) {
    //                 console.log('Request canceled', err.message)
    //             } else {
    //                 setError('Error fetching posts')
    //                 console.error(err)
    //             }
    //         }
    //     }

    //     fetchPosts()

    //     return () => {
    //         controller.abort()
    //     }
    // }, [])

    if (error) {
        return <div>{error}</div>
    }

    return (
        <Grid
            container
            spacing={2.8}
            sx={(theme) => ({
                padding: {
                    lg: 0,
                    md: '4.5rem',
                    sm: '2rem',
                    xs: '1.25rem',
                },
                // gap:'30px',
                // flexWrap:{
                //     lg:'nowrap',
                //     md:'wrap',
                //     sm:"wrap"
                // }
                justifyContent: 'center',
                alignItems: 'center',
                [theme.breakpoints.down('sm')]: {
                    width: '100%',
                    marginLeft: '0',
                },
            })}
        >
            {posts?.map((blogs) => {
                const featuredImage =
                    blogs._embedded?.['wp:featuredmedia']?.[0]?.source_url
                const postUrl = `${BASE_URL}/${blogs.date
                    .split('T')[0]
                    .replace(/-/g, '/')}/${blogs.slug}/`
                return (
                    <Grid
                        item
                        xs={12}
                        sm={6}
                        md={4}
                        sx={(theme) => ({
                            maxWidth: '412px',
                            [theme.breakpoints.down('sm')]: {
                                paddingLeft: '0 !important',
                                paddingTop: '0 !important',
                            },
                        })}
                        key={blogs.id}
                    >
                        <GradientBorderWrapper>
                            <ContentContainer>
                                <Box
                                    component={'img'}
                                    // src={blogs?.blogImage}
                                    src={featuredImage}
                                    alt="featuredImage"
                                    height={'247px'}
                                    borderRadius={'20px'}
                                    width={'100%'}
                                />
                                <DesignReadStyle variant="h5">
                                    {blogs?.read}{' '}
                                </DesignReadStyle>
                                <BlogTitle>
                                    {blogs?.title?.rendered}
                                    {/* {blogs?.blogSectionTitle}{' '} */}
                                </BlogTitle>
                                <BlogDescription
                                    component="div"
                                    dangerouslySetInnerHTML={{
                                        __html: blogs.excerpt.rendered,
                                    }}
                                >
                                    {/* {blogs?.blogSectionDescription}{' '} */}
                                </BlogDescription>

                                <Box
                                    sx={{
                                        display: 'flex',
                                        gap: '10px',
                                        alignItems: 'center',
                                    }}
                                >
                                    <Box
                                        component={'img'}
                                        src={Images?.BlogProfile}
                                        height={'32px'}
                                        width={'32px'}
                                        alt="BlogProfile"
                                    />
                                    <BlogContentDateName>
                                        {' '}
                                        {blogs?.BlogContentName}{' '}
                                    </BlogContentDateName>
                                    <BlogContentDateName>
                                        {' '}
                                        {blogs?.BlogContentDate}{' '}
                                    </BlogContentDateName>
                                    <a
                                        href={postUrl}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        Read More
                                    </a>
                                </Box>
                            </ContentContainer>
                        </GradientBorderWrapper>
                    </Grid>
                )
            })}
        </Grid>
    )
}
